import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FaDownload, FaFilter, FaEye, FaWallet, FaMoneyBillWave, FaChartLine, FaReceipt, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import { fetchWalletFunding } from '../../../services/WalletFundingService';
import { WalletFundingTransaction } from '../../../types/wallet-funding';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Container = styled.div`
  padding: 16px;
  max-width: 100%;
  overflow-x: hidden;
  
  @media (min-width: 768px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h1`
  color: #1a1a2e;
  margin: 0;
  font-size: 1.5rem;
`;

const FiltersContainer = styled(Paper)`
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StatusChip = styled(Chip)<{ $status: string }>`
  background-color: ${props => {
    switch (props.$status) {
      case 'completed':
        return '#4caf50';
      case 'pending':
        return '#ff9800';
      case 'failed':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }} !important;
  color: white !important;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: #666;
`;

const DetailValue = styled.span`
  color: #333;
`;

const SummaryContainer = styled(Grid)`
  margin-bottom: 24px;
`;

const SummaryCard = styled(Paper)`
  padding: 20px;
  height: 100%;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
`;

const StatsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;

  svg {
    font-size: 24px;
  }
`;

interface Summary {
  totalAmount: string;
  totalDeposits: number;
  platformCharges: string;
  netAmount: string;
}

const WalletFundingReport: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [meterNumber, setMeterNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<WalletFundingTransaction[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedTransaction, setSelectedTransaction] = useState<WalletFundingTransaction | null>(null);
  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [summaryData, setSummaryData] = useState<Summary>({
    totalAmount: '0',
    totalDeposits: 0,
    platformCharges: '0',
    netAmount: '0',
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchWalletFunding({
        page: page + 1,
        limit: rowsPerPage,
        startDate,
        endDate,
        meterNumber: meterNumber || undefined,
      });
      setData(response.transactions);
      setTotalCount(response.pagination.total);
      setSummaryData({
        totalAmount: response.totals.total_amount,
        totalDeposits: response.pagination.total,
        platformCharges: response.totals.platform_charge,
        netAmount: response.totals.net_amount,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchData();
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportToExcel = () => {
    handleExportClose();
    
    const exportData = data.map(item => ({
      'Reference ID': item.id,
      'Resident Name': item.user.name,
      'Email': item.user.email,
      'Meter Number': item.meter_number,
      'Total Amount': parseFloat(item.total_amount),
      'Net Amount': parseFloat(item.net_amount),
      'Platform Charge': parseFloat(item.platform_charge),
      'Gateway Charge': parseFloat(item.gateway_charge),
      'Balance Before': parseFloat(item.balance_before_funding),
      'Balance After': parseFloat(item.balance_after_funding),
      'Status': item.status,
      'Date': new Date(item.created_at).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Wallet Funding Report');
    
    // Generate filename with current date
    const fileName = `wallet-funding-report-${new Date().toISOString().split('T')[0]}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const exportToPDF = () => {
    handleExportClose();
    
    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(16);
    doc.text('Wallet Funding Report', 14, 15);
    
    // Add summary section
    doc.setFontSize(12);
    doc.text(`Report Period: ${startDate} to ${endDate}`, 14, 25);
    doc.text(`Total Amount: ${formatAmount(summaryData.totalAmount)}`, 14, 32);
    doc.text(`Total Deposits: ${summaryData.totalDeposits}`, 14, 39);
    doc.text(`Platform Charges: ${formatAmount(summaryData.platformCharges)}`, 14, 46);
    doc.text(`Net Amount: ${formatAmount(summaryData.netAmount)}`, 14, 53);

    // Prepare table data
    const tableData = data.map(item => [
      item.id.toString(),
      item.user.name,
      item.meter_number,
      formatAmount(item.total_amount),
      new Date(item.created_at).toLocaleDateString(),
      item.status,
    ]);

    // Add table
    (doc as any).autoTable({
      head: [['Reference', 'Resident', 'Meter Number', 'Amount', 'Date', 'Status']],
      body: tableData,
      startY: 60,
      theme: 'grid',
      styles: { fontSize: 8 },
      headStyles: { fillColor: [26, 26, 46] },
    });

    // Generate filename with current date
    const fileName = `wallet-funding-report-${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
  };

  const formatAmount = (amount: string) => {
    return `₦${parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Container>
      <Header>
        <Title>Wallet Funding Report</Title>
        <ActionButtons>
          <Button
            variant="contained"
            startIcon={<FaDownload />}
            onClick={handleExportClick}
          >
            Export
          </Button>
          <Menu
            anchorEl={exportAnchorEl}
            open={Boolean(exportAnchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={exportToPDF}>
              <FaFilePdf style={{ marginRight: '8px' }} /> Export as PDF
            </MenuItem>
            <MenuItem onClick={exportToExcel}>
              <FaFileExcel style={{ marginRight: '8px' }} /> Export as Excel
            </MenuItem>
          </Menu>
        </ActionButtons>
      </Header>

      <Box mb={3}>
        <SummaryContainer container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaWallet />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Total Amount
              </Typography>
              <Typography variant="h5">
                {formatAmount(summaryData.totalAmount)}
              </Typography>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaReceipt />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Total Deposits
              </Typography>
              <Typography variant="h5">
                {summaryData.totalDeposits}
              </Typography>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaMoneyBillWave />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Platform Charges
              </Typography>
              <Typography variant="h5">
                {formatAmount(summaryData.platformCharges)}
              </Typography>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaChartLine />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Net Amount
              </Typography>
              <Typography variant="h5">
                {formatAmount(summaryData.netAmount)}
              </Typography>
            </SummaryCard>
          </Grid>
        </SummaryContainer>
      </Box>

      <FiltersContainer elevation={0}>
        <FilterGroup>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} width="100%">
            <TextField
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              label="Meter Number"
              value={meterNumber}
              onChange={(e) => setMeterNumber(e.target.value)}
              size="small"
              placeholder="Enter meter number"
              fullWidth
            />
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSearch}
              sx={{ height: '40px' }}
              fullWidth
            >
              Search
            </LoadingButton>
          </Box>
        </FilterGroup>
      </FiltersContainer>

      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reference</TableCell>
                <TableCell>Resident</TableCell>
                <TableCell>Meter Number</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell>{row.meter_number}</TableCell>
                  <TableCell align="right">{formatAmount(row.total_amount)}</TableCell>
                  <TableCell>{formatDate(row.created_at)}</TableCell>
                  <TableCell>
                    <StatusChip
                      label={row.status}
                      $status={row.status}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => setSelectedTransaction(row)}
                      title="View Details"
                    >
                      <FaEye />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Dialog
        open={!!selectedTransaction}
        onClose={() => setSelectedTransaction(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Transaction Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedTransaction && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailRow>
                  <DetailLabel>Reference ID:</DetailLabel>
                  <DetailValue>{selectedTransaction.id}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Resident Name:</DetailLabel>
                  <DetailValue>{selectedTransaction.user.name}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Email:</DetailLabel>
                  <DetailValue>{selectedTransaction.user.email}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Meter Number:</DetailLabel>
                  <DetailValue>{selectedTransaction.meter_number}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Bank:</DetailLabel>
                  <DetailValue>{selectedTransaction.bank_name}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Account Number:</DetailLabel>
                  <DetailValue>{selectedTransaction.account_number}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Total Amount:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.total_amount)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Net Amount:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.net_amount)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Platform Charge:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.platform_charge)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Gateway Charge:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.gateway_charge)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Balance Before:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.balance_before_funding)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Balance After:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.balance_after_funding)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Date:</DetailLabel>
                  <DetailValue>{formatDate(selectedTransaction.created_at)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Status:</DetailLabel>
                  <DetailValue>
                    <StatusChip
                      label={selectedTransaction.status}
                      $status={selectedTransaction.status}
                      size="small"
                    />
                  </DetailValue>
                </DetailRow>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedTransaction(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WalletFundingReport; 