import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FaDownload, FaEye, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import { fetchPayouts } from '../../../services/PayoutService';
import { Payout, ServiceType, SERVICE_TYPE_OPTIONS } from '../../../types/payout';
import { Estate } from '../../../types/admin';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Container = styled.div`
  padding: 16px;
  max-width: 100%;
  overflow-x: hidden;
  
  @media (min-width: 768px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h1`
  color: #1a1a2e;
  margin: 0;
  font-size: 1.5rem;
`;

const FiltersContainer = styled(Paper)`
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StatusChip = styled(Chip)<{ $status: string }>`
  background-color: ${props => {
    switch (props.$status) {
      case 'approved':
        return '#4caf50';
      case 'pending':
        return '#ff9800';
      case 'rejected':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }} !important;
  color: white !important;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: #666;
`;

const DetailValue = styled.span`
  color: #333;
`;

interface PayoutReportProps {
  estates: Estate[];
}

const PayoutReport: React.FC<PayoutReportProps> = ({ estates }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedEstate, setSelectedEstate] = useState<string>('');
  const [selectedServiceType, setSelectedServiceType] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Payout[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPayout, setSelectedPayout] = useState<Payout | null>(null);
  const [totalAmount, setTotalAmount] = useState('0');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchPayouts({
        page: page + 1,
        limit: rowsPerPage,
        estate_id: selectedEstate ? Number(selectedEstate) : undefined,
        service_type: selectedServiceType || undefined,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
      });
      
      setData(response.data.payouts);
      setTotalCount(response.data.pagination.total);
      const total = response.data.payouts.reduce((sum, payout) => sum + parseFloat(payout.amount), 0);
      setTotalAmount(total.toString());
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchData();
  };

  const exportToExcel = () => {
    const exportData = data.map(item => ({
      'Reference ID': item.id,
      'Estate': item.estate.name,
      'Estate Address': item.estate.address,
      'Service Types': item.service_type,
      'Period Start': new Date(item.transactions_period_start).toLocaleDateString(),
      'Period End': new Date(item.transactions_period_end).toLocaleDateString(),
      'Amount': parseFloat(item.amount),
      'Created At': new Date(item.created_at).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payout Report');
    
    const fileName = `payout-report-${new Date().toISOString().split('T')[0]}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    
    doc.setFontSize(16);
    doc.text('Payout Report', 14, 15);
    
    doc.setFontSize(12);
    doc.text(`Report Period: ${startDate || 'All time'} to ${endDate || 'Present'}`, 14, 25);
    doc.text(`Total Amount: ₦${parseFloat(totalAmount).toLocaleString()}`, 14, 32);

    const tableData = data.map(item => [
      item.id.toString(),
      item.estate.name,
      item.service_type,
      `₦${parseFloat(item.amount).toLocaleString()}`,
      new Date(item.created_at).toLocaleDateString(),
    ]);

    (doc as any).autoTable({
      head: [['Reference', 'Estate', 'Service Types', 'Amount', 'Date']],
      body: tableData,
      startY: 40,
      theme: 'grid',
      styles: { fontSize: 8 },
      headStyles: { fillColor: [26, 26, 46] },
    });

    const fileName = `payout-report-${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
  };

  const formatAmount = (amount: string) => {
    return `₦${parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <Container>
      <Header>
        <Title>Payout Report</Title>
        <ActionButtons>
          <Button
            variant="outlined"
            startIcon={<FaFileExcel />}
            onClick={exportToExcel}
          >
            Export Excel
          </Button>
          <Button
            variant="outlined"
            startIcon={<FaFilePdf />}
            onClick={exportToPDF}
          >
            Export PDF
          </Button>
        </ActionButtons>
      </Header>

      <FiltersContainer elevation={0}>
        <FilterGroup>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} width="100%">
            <FormControl fullWidth size="small">
              <InputLabel>Estate</InputLabel>
              <Select
                value={selectedEstate}
                onChange={(e) => setSelectedEstate(e.target.value)}
              >
                <MenuItem value="">All Estates</MenuItem>
                {estates.map((estate) => (
                  <MenuItem key={estate.id} value={estate.id}>
                    {estate.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Service Type</InputLabel>
              <Select
                value={selectedServiceType}
                onChange={(e) => setSelectedServiceType(e.target.value)}
              >
                <MenuItem value="">All Services</MenuItem>
                {SERVICE_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />

            <TextField
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />

            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSearch}
              sx={{ height: '40px' }}
              fullWidth
            >
              Search
            </LoadingButton>
          </Box>
        </FilterGroup>
      </FiltersContainer>

      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reference</TableCell>
                <TableCell>Estate</TableCell>
                <TableCell>Service Types</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Period</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.estate.name}</TableCell>
                  <TableCell>{row.service_type}</TableCell>
                  <TableCell align="right">{formatAmount(row.amount)}</TableCell>
                  <TableCell>
                    {formatDate(row.transactions_period_start)} - {formatDate(row.transactions_period_end)}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => setSelectedPayout(row)}
                      title="View Details"
                    >
                      <FaEye />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Dialog
        open={!!selectedPayout}
        onClose={() => setSelectedPayout(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Payout Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedPayout && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailRow>
                  <DetailLabel>Reference ID:</DetailLabel>
                  <DetailValue>{selectedPayout.id}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Estate:</DetailLabel>
                  <DetailValue>{selectedPayout.estate.name}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Estate Address:</DetailLabel>
                  <DetailValue>{selectedPayout.estate.address}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Service Types:</DetailLabel>
                  <DetailValue>{selectedPayout.service_type}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Period:</DetailLabel>
                  <DetailValue>
                    {formatDate(selectedPayout.transactions_period_start)} - {formatDate(selectedPayout.transactions_period_end)}
                  </DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Amount:</DetailLabel>
                  <DetailValue>{formatAmount(selectedPayout.amount)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Created At:</DetailLabel>
                  <DetailValue>{new Date(selectedPayout.created_at).toLocaleString()}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Proof of Payment:</DetailLabel>
                  <DetailValue>
                    <Button
                      href={selectedPayout.proof_file}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<FaDownload />}
                      size="small"
                    >
                      Download
                    </Button>
                  </DetailValue>
                </DetailRow>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedPayout(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PayoutReport; 