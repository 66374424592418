import client from './api';
import { AdminLoginResponse, AdminData, AdminLoginCredentials } from '../types/admin';

const ADMIN_DATA_KEY = 'adminData';
const TOKEN_KEY = 'authToken';

export const login = async (credentials: AdminLoginCredentials): Promise<AdminData> => {
  try {
    const { data } = await client.post<AdminLoginResponse>('/api/admin/login', credentials);
    if (data.status && data.data) {
      // Store admin data and token
      localStorage.setItem(ADMIN_DATA_KEY, JSON.stringify(data.data));
      localStorage.setItem(TOKEN_KEY, data.data.access_token);
      
      return data.data;
    }
    throw new Error(data.message || 'Login failed');
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem(ADMIN_DATA_KEY);
  localStorage.removeItem(TOKEN_KEY);
  delete client.defaults.headers.common['Authorization'];
};

export const getCurrentAdmin = (): AdminData | null => {
  const adminData = localStorage.getItem(ADMIN_DATA_KEY);
  if (adminData) {
    return JSON.parse(adminData);
  }
  return null;
};

export const isAuthenticated = (): boolean => {
  return !!localStorage.getItem(TOKEN_KEY);
};

export const isSuperAdmin = (admin: AdminData | null): boolean => {
  return admin?.adminType === 'Super Admin';
};

// Initialize auth header if token exists
const token = localStorage.getItem(TOKEN_KEY);
if (token) {
  client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
} 