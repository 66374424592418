import client from './api';
import { DepositFilter, DepositResponse } from '../types/reports';

export const fetchWaterDepositHistory = async (params: DepositFilter): Promise<DepositResponse> => {
  const response = await client.post('/api/payment/waterHistory', {
    page: params.page,
    limit: params.limit,
    from: params.startDate,
    to: params.endDate,
    estate_id: params.estate_id,
    user_id: params.user_id,
    status: params.status,
    manager_id: params.manager_id,
  });
  return response.data;
};

export const fetchDieselDepositHistory = async (params: DepositFilter): Promise<DepositResponse> => {
  const response = await client.post('/api/payment/dieselHistory', {
    page: params.page,
    limit: params.limit,
    from: params.startDate,
    to: params.endDate,
    estate_id: params.estate_id,
    user_id: params.user_id,
    status: params.status,
    manager_id: params.manager_id,
  });
  return response.data;
}; 