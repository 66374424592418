import React from 'react';
// import { NativeBaseProvider } from 'native-base';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import ResidentManagement from './pages/admin/ResidentManagement';
import EstateManagement from './pages/admin/EstateManagement';
import PendingApprovals from './pages/admin/PendingApprovals';
import EstateSetup from './pages/admin/EstateSetup';
import AddNewEstate from './pages/admin/AddNewEstate';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RequestAccountDeletion from './pages/RequestAccountDeletion';
import WalletFundingReport from './pages/admin/reports/WalletFundingReport';
import VendReport from './pages/admin/reports/VendReport';
import ServiceFeeReport from './pages/admin/reports/ServiceFeeReport';
import WaterDepositReport from './pages/admin/reports/WaterDepositReport';
import DieselDepositReport from './pages/admin/reports/DieselDepositReport';
import VisitorsManagementReport from './pages/admin/reports/VisitorsManagementReport';
import PayoutReport from './pages/admin/reports/PayoutReport';
import CreatePayout from './pages/admin/payouts/CreatePayout';
import ManualVend from './pages/admin/vend/ManualVend';
import VendRequests from './pages/admin/vend/VendRequests';
import ReverifyPayment from './pages/admin/ReverifyPayment';
import { useEstates } from './hooks/useEstates';
import { CircularProgress } from '@mui/material';
import { getCurrentAdmin } from './services/AdminService';
import { isSuperAdmin } from './types/admin';
import { Estate } from './types/Estate';
import { AdminData } from './types/admin';

const App: React.FC = () => {
  const { estates, loading, error } = useEstates();

  const SuperAdminRoute = ({ children }: { children: React.ReactNode }) => {
    const admin = getCurrentAdmin();
    if (loading) return <CircularProgress />;
    if (error) return <div>Error: {error}</div>;
    if (!admin || !isSuperAdmin(admin)) return <Navigate to="/admin" replace />;
    return <>{children}</>;
  };

  const ProtectedReport = ({ component: Component }: { component: React.ComponentType<{ admin: AdminData }> }) => {
    const admin = getCurrentAdmin();
    if (!admin) return <Navigate to="/admin/login" replace />;
    return <Component admin={admin} />;
  };

  return (
    // <NativeBaseProvider>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/account-deletion' element={<RequestAccountDeletion />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            
            {/* Admin routes */}
            <Route path="/admin" element={<AdminDashboard />}>
              <Route path="residents" element={<ResidentManagement />} />
              <Route path="newusers" element={<PendingApprovals />} />
              <Route path="reports/wallet-funding" element={<WalletFundingReport />} />
              <Route path="reports/vend" element={<VendReport />} />
              <Route path="reports/service-fee" element={<ServiceFeeReport />} />
              <Route 
                path="reports/water-deposit" 
                element={<ProtectedReport component={WaterDepositReport} />} 
              />
              <Route 
                path="reports/diesel-deposit" 
                element={<ProtectedReport component={DieselDepositReport} />} 
              />
              <Route path="reports/visitors" element={<VisitorsManagementReport />} />
              <Route 
                path="reports/payout" 
                element={
                  <SuperAdminRoute>
                    <PayoutReport estates={estates as Estate[]} />
                  </SuperAdminRoute>
                }
              />
              <Route 
                path="payouts" 
                element={
                  <SuperAdminRoute>
                    <CreatePayout estates={estates as Estate[]} />
                  </SuperAdminRoute>
                }
              />
              <Route path="vend/manual" element={<ManualVend />} />
              <Route path="vend/requests" element={<VendRequests />} />
              <Route path="estates" element={<EstateManagement />} />
              <Route path="estates/:estateId" element={<EstateSetup />} />
              <Route path="estates/new" element={<AddNewEstate />} />
              <Route path="reverify-payment" element={<ReverifyPayment />} />
            </Route>

            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              {/* <Route path="/power-purchase" element={<PowerPurchase />} /> */}
              {/* <Route path="/service-fee" element={<ServiceFee />} /> */}
            </Route>

            {/* Fallback for undefined routes */}
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>
    // </NativeBaseProvider>
  );
};

export default App;