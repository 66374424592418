export type ServiceType = 'fee' | 'vend' | 'water' | 'diesel';

export interface PayoutRequest {
  manager_user_id: number;
  estate_id: number;
  service_type: ServiceType;
  transactions_period_start: string;
  transactions_period_end: string;
  amount: string;
  proof_file: string | File;
}

export interface Payout {
  id: number;
  manager_user_id: number;
  estate_id: number;
  service_type: string;
  transactions_period_start: string;
  transactions_period_end: string;
  amount: string;
  proof_file: string;
  created_at: string;
  updated_at: string;
  estate: {
    name: string;
    address: string;
  };
}

export interface PayoutResponse {
  status: boolean;
  message: string;
  data: {
    payouts: Payout[];
    pagination: {
      total: number;
      pages: number;
      currentPage: number;
      limit: number;
    };
  };
}

export const SERVICE_TYPE_OPTIONS: { label: string; value: ServiceType }[] = [
  { label: 'Service Fee', value: 'fee' },
  { label: 'Vending', value: 'vend' },
  { label: 'Water', value: 'water' },
  { label: 'Diesel', value: 'diesel' },
]; 