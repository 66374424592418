import client from './api';
import { ServiceFeeResponse } from '../types/serviceFee';

export interface FetchServiceFeeParams {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  status?: string;
  meterNumber?: string;
  estateId?: number[];
  merchant_id?: number;
}

export const fetchServiceFeeTransactions = async ({
  page = 1,
  limit = 10,
  startDate,
  endDate,
  meterNumber,
  estateId,
  status,
  merchant_id,
}: FetchServiceFeeParams): Promise<ServiceFeeResponse> => {
  try {
    const params = new URLSearchParams();
    
    // Add basic params
    params.append('page', page.toString());
    params.append('limit', limit.toString());
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    if (meterNumber) params.append('meterNumber', meterNumber);
    if (status) params.append('status', status);
    if (merchant_id) params.append('merchant_id', merchant_id.toString());
    
    // Add multiple estateIds if present
    if (estateId && estateId.length > 0) {
      estateId.forEach(id => params.append('estateId', id.toString()));
    }

    const response = await client.get('/api/admin/reports/service-fee', { params });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching service fee transactions:', error);
    throw error;
  }
}; 