import client from "./api";

interface VerifyPaymentParams {
  meterNumber: string;
  transactionDate: string;
}

/**
 * Verify and vend a payment transaction
 * @param params Object containing meterNumber and transactionDate
 * @returns Promise containing verification response
 */
export const verifyAndVendPayment = async (params: VerifyPaymentParams): Promise<any> => {
  try {
    const response = await client.post('/api/admin/transactions/verify-and-vend', params);
    return response.data;
  } catch (error) {
    console.error('Error verifying payment:', error);
    throw error;
  }
};
