import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
  Menu,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FaDownload, FaMoneyBillWave, FaCalendar, FaChartLine, FaFilePdf, FaFileExcel, FaEye } from 'react-icons/fa';
import { fetchServiceFeeTransactions } from '../../../services/ServiceFeeService';
import { ServiceFeeTransaction, ServiceFeeResponse, ServiceFeeTotals } from '../../../types/serviceFee';
import { useEstates } from '../../../hooks/useEstates';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getCurrentAdmin } from '../../../services/AdminService';
import { isSuperAdmin } from '../../../types/admin';

declare module 'jspdf' {
  interface jsPDF {
    saveGraphicsState(): void;
    setGState(gstate: { opacity: number }): void;
    restoreGraphicsState(): void;
  }
}

const Container = styled.div`
  padding: 16px;
  max-width: 100%;
  overflow-x: hidden;
  
  @media (min-width: 768px) {
    padding: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h1`
  color: #1a1a2e;
  margin: 0;
  font-size: 1.5rem;
`;

const FiltersContainer = styled(Paper)`
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SummaryContainer = styled(Grid)`
  margin-bottom: 24px;
`;

const SummaryCard = styled(Paper)`
  padding: 20px;
  height: 100%;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
`;

const StatsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;

  svg {
    font-size: 24px;
  }
`;

const StatusChip = styled(Chip)<{ $status: string }>`
  background-color: ${props => {
    switch (props.$status.toLowerCase()) {
      case 'successful':
        return '#4caf50';
      case 'processing':
        return '#ff9800';
      case 'failed':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }} !important;
  color: white !important;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: #666;
`;

const DetailValue = styled.span`
  color: #333;
`;

interface ServiceFeeReportState {
  transactions: ServiceFeeTransaction[];
  totals: ServiceFeeTotals;
  pagination: {
    total: number;
    pages: number;
    currentPage: number;
    limit: number;
  };
}

const ServiceFeeReport: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [meterNumber, setMeterNumber] = useState('');
  const [estateIds, setEstateIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ServiceFeeReportState>({
    transactions: [],
    totals: {
      total_transactions: 0,
      total_amount: 0,
      total_months: 0,
      average_amount: 0,
      min_amount: 0,
      max_amount: 0
    },
    pagination: {
      total: 0,
      pages: 0,
      currentPage: 1,
      limit: 10
    }
  });
  const [selectedTransaction, setSelectedTransaction] = useState<ServiceFeeTransaction | null>(null);
  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { estates, loading: estatesLoading } = useEstates();

  const fetchData = async () => {
    try {
      setLoading(true);
      const currentAdmin = getCurrentAdmin();
      if (!currentAdmin) return;

      const response = await fetchServiceFeeTransactions({
        page: page + 1,
        limit: rowsPerPage,
        startDate,
        endDate,
        meterNumber: meterNumber || undefined,
        estateId: estateIds.length > 0 ? estateIds.map(id => parseInt(id)) : undefined,
        status: status || undefined,
        merchant_id: !isSuperAdmin(currentAdmin) ? currentAdmin.id : undefined,
      });
      setData({
        transactions: response.transactions,
        totals: response.totals,
        pagination: response.pagination
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchData();
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const getPaymentMode = (txref: string) => {
    if (txref.toLowerCase().startsWith('wal')) return 'Wallet';
    if (txref.toLowerCase().startsWith('pay')) return 'Paystack';
    return 'Admin';
  };

  const exportToExcel = () => {
    handleExportClose();
    
    const exportData = data.transactions.map(item => ({
      'ID': item.id,
      'Customer Name': item.customerName,
      'Email': item.customerEmail,
      'Phone': item.phoneNumber,
      'House Number': item.houseNumber,
      'Amount': parseFloat(item.amount),
      'Payment Mode': getPaymentMode(item.paymentReference),
      'Due Date': new Date(item.paymentDate).toLocaleString(),
      'Date': new Date(item.createdAt).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Service Fee Report');
    
    const fileName = `service-fee-report-${new Date().toISOString().split('T')[0]}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const exportToPDF = () => {
    handleExportClose();
    
    const doc = new jsPDF();
    
    // Add logo
    const logo = new Image();
    logo.src = '/logo.png'; // Make sure this path is correct
    doc.addImage(logo, 'PNG', 14, 10, 40, 40);
    
    // Add watermark (using a different approach)
    doc.setFillColor(200, 200, 200);
    doc.setTextColor(200, 200, 200);
    doc.setFontSize(60);
    doc.text('RESSYDENT', 50, 100, { angle: 45 });
    
    // Add header
    doc.setFontSize(20);
    doc.setTextColor(26, 26, 46);
    doc.text('Service Fee Report', 14, 70);
    
    // Add report details
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report Period: ${startDate} to ${endDate}`, 14, 85);
    if (estateIds.length > 0) {
      const selectedEstates = estates.filter(estate => estateIds.includes(estate.id.toString()));
      if (selectedEstates.length > 0) {
        doc.text(`Estate: ${selectedEstates.map(estate => estate.name).join(', ')}`, 14, 95);
        // doc.text(`Total Amount: NGN ${data.totals.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`, 14, 105);
        doc.text(`Total Transactions: ${data.totals.total_transactions}`, 14, 115);
        doc.text(`Average Amount: NGN ${data.totals.average_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`, 14, 125);

        const pdfTableData = data.transactions.map(item => [
          item.id.toString(),
          item.customerName,
          item.houseNumber,
          `NGN ${parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
          getPaymentMode(item.paymentReference),
          item.numberOfMonths,
          new Date(item.paymentDate).toLocaleDateString(),
        ]);

        (doc as any).autoTable({
          head: [['ID', 'Customer', 'House Number', 'Amount', 'Payment Mode', 'Months', 'Due Date']],
          body: pdfTableData,
          startY: 135,
          theme: 'grid',
          styles: { fontSize: 8 },
          headStyles: { fillColor: [26, 26, 46] },
        });
      }
    } else {
      // doc.text(`Total Amount: NGN ${data.totals.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`, 14, 95);
      doc.text(`Total Transactions: ${data.totals.total_transactions}`, 14, 105);
      doc.text(`Average Amount: NGN ${data.totals.average_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`, 14, 115);

      const pdfTableData = data.transactions.map(item => [
        item.id.toString(),
        item.customerName,
        item.houseNumber,
        `NGN ${parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
        getPaymentMode(item.paymentReference),
        item.numberOfMonths,
        new Date(item.paymentDate).toLocaleDateString(),
      ]);

      (doc as any).autoTable({
        head: [['ID', 'Customer', 'House Number', 'Amount', 'Payment Mode', 'Months', 'Due Date']],
        body: pdfTableData,
        startY: 125,
        theme: 'grid',
        styles: { fontSize: 8 },
        headStyles: { fillColor: [26, 26, 46] },
      });
    }

    const fileName = `Ressydent-service-fee-report-${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
  };

  const formatAmount = (amount: string | number) => {
    const value = typeof amount === 'string' ? parseFloat(amount) : amount;
    return `₦${value.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Container>
      <Header>
        <Title>Service Fee Report</Title>
        <ActionButtons>
          <Button
            variant="contained"
            startIcon={<FaDownload />}
            onClick={handleExportClick}
          >
            Export
          </Button>
          <Menu
            anchorEl={exportAnchorEl}
            open={Boolean(exportAnchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={exportToPDF}>
              <FaFilePdf style={{ marginRight: '8px' }} /> Export as PDF
            </MenuItem>
            <MenuItem onClick={exportToExcel}>
              <FaFileExcel style={{ marginRight: '8px' }} /> Export as Excel
            </MenuItem>
          </Menu>
        </ActionButtons>
      </Header>

      <Box mb={3}>
        <SummaryContainer container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaMoneyBillWave />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Total Amount
              </Typography>
              <Typography variant="h5">
                {formatAmount(data.totals.total_amount)}
              </Typography>
            </SummaryCard>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaCalendar />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Total Months
              </Typography>
              <Typography variant="h5">
                {data.totals.total_months.toLocaleString()}
              </Typography>
            </SummaryCard>
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaChartLine />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Total Transactions
              </Typography>
              <Typography variant="h5">
                {data.totals.total_transactions.toLocaleString()}
              </Typography>
            </SummaryCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard>
              <StatsIcon>
                <FaMoneyBillWave />
              </StatsIcon>
              <Typography variant="body1" gutterBottom>
                Average Amount
              </Typography>
              <Typography variant="h5">
                {formatAmount(data.totals.average_amount)}
              </Typography>
            </SummaryCard>
          </Grid>
        </SummaryContainer>
      </Box>

      <FiltersContainer elevation={0}>
        <FilterGroup>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} width="100%">
            <TextField
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              label="Meter Number"
              value={meterNumber}
              onChange={(e) => setMeterNumber(e.target.value)}
              size="small"
              placeholder="Enter meter number"
              fullWidth
            />
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              size="small"
              fullWidth
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Successful">Successful</MenuItem>
              <MenuItem value="Processing">Processing</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
            </TextField>
            <TextField
              select
              label="Estate"
              value={estateIds}
              onChange={(e) => setEstateIds(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
              size="small"
              fullWidth
              disabled={estatesLoading}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as string[]).map((value) => {
                      const estate = estates.find(e => e.id.toString() === value);
                      return estate ? (
                        <Chip key={value} label={estate.name} size="small" />
                      ) : null;
                    })}
                  </Box>
                ),
              }}
            >
              {estates.map((estate) => (
                <MenuItem key={estate.id} value={estate.id.toString()}>
                  {estate.name}
                </MenuItem>
              ))}
            </TextField>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSearch}
              sx={{ height: '40px' }}
              fullWidth
            >
              Search
            </LoadingButton>
          </Box>
        </FilterGroup>
      </FiltersContainer>

      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Customer</TableCell>
                {/* <TableCell>Meter Number</TableCell> */}
                <TableCell>House Number</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Months</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.transactions.map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.customerName}</TableCell>
                  {/* <TableCell>{row.meterNumber}</TableCell> */}
                  <TableCell>{row.houseNumber}</TableCell>
                  <TableCell align="right">{formatAmount(row.amount)}</TableCell>
                  <TableCell>{getPaymentMode(row.paymentReference)}</TableCell>
                  <TableCell>{row.numberOfMonths}</TableCell>
                  <TableCell>{formatDate(row.paymentDate)}</TableCell>
                  <TableCell>
                    <StatusChip
                      label={row.paymentStatus}
                      $status={row.paymentStatus}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => setSelectedTransaction(row)}
                      title="View Details"
                    >
                      <FaEye />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      <TablePagination
        component="div"
        count={data.pagination.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Dialog
        open={!!selectedTransaction}
        onClose={() => setSelectedTransaction(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Transaction Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedTransaction && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailRow>
                  <DetailLabel>ID:</DetailLabel>
                  <DetailValue>{selectedTransaction.id}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Customer Name:</DetailLabel>
                  <DetailValue>{selectedTransaction.customerName}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Email:</DetailLabel>
                  <DetailValue>{selectedTransaction.customerEmail}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Phone:</DetailLabel>
                  <DetailValue>{selectedTransaction.phoneNumber}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>House Number:</DetailLabel>
                  <DetailValue>{selectedTransaction.houseNumber}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Meter Number:</DetailLabel>
                  <DetailValue>{selectedTransaction.meterNumber}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Amount:</DetailLabel>
                  <DetailValue>{formatAmount(selectedTransaction.amount)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Number of Months:</DetailLabel>
                  <DetailValue>{selectedTransaction.numberOfMonths}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Due Date:</DetailLabel>
                  <DetailValue>{formatDate(selectedTransaction.paymentDate)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Payment Reference:</DetailLabel>
                  <DetailValue>{selectedTransaction.paymentReference}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Created At:</DetailLabel>
                  <DetailValue>{formatDate(selectedTransaction.createdAt)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Status:</DetailLabel>
                  <DetailValue>
                    <StatusChip
                      label={selectedTransaction.paymentStatus}
                      $status={selectedTransaction.paymentStatus}
                      size="small"
                    />
                  </DetailValue>
                </DetailRow>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedTransaction(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ServiceFeeReport; 