import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaGooglePlay, FaApple } from 'react-icons/fa';

const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background-color: rgba(30, 41, 59, 0.98);
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 15px 20px;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LogoImage = styled.img`
  height: 32px;
  width: auto;
`;

const Logo = styled(Link)`
  font-size: 1.8rem;
  font-weight: 700;
  color: #f8fafc;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    color: #0ea5e9;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const NavLink = styled(Link)`
  font-size: 1.1rem;
  color: #f8fafc;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    color: #0ea5e9;
    background: rgba(255, 255, 255, 0.1);
  }
`;

const AppStoreLinks = styled.div`
  display: flex;
  gap: 15px;
  margin-left: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StoreLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: #f8fafc;
  text-decoration: none;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const AdminLink = styled(Link)`
  font-size: 1.1rem;
  color: #f8fafc;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 8px;
  background: linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(14, 165, 233, 0.3);
  }
`;

const NavBar: React.FC = () => {
  return (
    <NavBarContainer>
      <LogoSection>
        <Logo to="/">
          <LogoImage src={process.env.PUBLIC_URL + '/logo.png'} alt="Ressydent" />
          Ressydent
        </Logo>
      </LogoSection>
      <NavLinks>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <AppStoreLinks>
          <StoreLink href="https://play.google.com/store/apps/details?id=com.Ressydent&hl=en" target="_blank" rel="noopener noreferrer">
            <FaGooglePlay /> Play Store
          </StoreLink>
          <StoreLink href="https://apps.apple.com/ng/app/ressydent/id6741027949" target="_blank" rel="noopener noreferrer">
            <FaApple /> App Store
          </StoreLink>
        </AppStoreLinks>
        <AdminLink to="/admin/login">Admin Login</AdminLink>
      </NavLinks>
    </NavBarContainer>
  );
};

export default NavBar;
