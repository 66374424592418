import React from 'react';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaClock, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const PageContainer = styled.div`
  font-family: 'Inter', sans-serif;
  padding-top: 70px; // Account for fixed navbar
`;

const HeroSection = styled.div`
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SubHeader = styled.p`
  font-size: 1.4rem;
  line-height: 1.8;
  margin-bottom: 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.9);
`;

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

const ContactCard = styled.div`
  background: white;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
`;

const ContactIcon = styled.div`
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: white;
  font-size: 24px;
`;

const ContactTitle = styled.h3`
  font-size: 1.4rem;
  color: #1a1a2e;
  margin-bottom: 15px;
  font-weight: 600;
`;

const ContactText = styled.p`
  font-size: 1.1rem;
  color: #475569;
  line-height: 1.6;
  margin: 0;
`;

const ContactLink = styled.a`
  color: #0ea5e9;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    color: #0284c7;
    text-decoration: underline;
  }
`;

const SocialSection = styled.div`
  text-align: center;
  background: #f8fafc;
  padding: 60px 20px;
  border-radius: 16px;
`;

const SocialTitle = styled.h2`
  font-size: 2rem;
  color: #1a1a2e;
  margin-bottom: 30px;
  font-weight: 700;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const SocialLink = styled.a`
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0ea5e9;
  font-size: 24px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    background: #0ea5e9;
    color: white;
  }
`;

const Contact: React.FC = () => {
  return (
    <PageContainer>
      <TopNavBar />
      
      <HeroSection>
        <HeroContent>
          <Header>Contact Us</Header>
          <SubHeader>
            We're here to help! Reach out to us through any of our communication channels.
          </SubHeader>
        </HeroContent>
      </HeroSection>

      <MainContent>
        <ContactGrid>
          <ContactCard>
            <ContactIcon>
              <FaEnvelope />
            </ContactIcon>
            <ContactTitle>Email Support</ContactTitle>
            <ContactText>
              <ContactLink href="mailto:support@ressytech.com">support@ressytech.com</ContactLink>
            </ContactText>
          </ContactCard>

          <ContactCard>
            <ContactIcon>
              <FaEnvelope />
            </ContactIcon>
            <ContactTitle>Social Media</ContactTitle>
            <ContactText>
              <ContactLink href="mailto:socials@ressydent.com">socials@ressydent.com</ContactLink>
            </ContactText>
          </ContactCard>

          <ContactCard>
            <ContactIcon>
              <FaPhone />
            </ContactIcon>
            <ContactTitle>Phone</ContactTitle>
            <ContactText>
              <ContactLink href="tel:+2349031194921">+234 903 119 4921</ContactLink>
            </ContactText>
          </ContactCard>

          <ContactCard>
            <ContactIcon>
              <FaClock />
            </ContactIcon>
            <ContactTitle>Business Hours</ContactTitle>
            <ContactText>
              Monday - Friday<br />
              8:00 AM - 5:00 PM WAT
            </ContactText>
          </ContactCard>
        </ContactGrid>

        <SocialSection>
          <SocialTitle>Connect With Us</SocialTitle>
          <SocialLinks>
            <SocialLink href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </SocialLink>
            <SocialLink href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialLink>
            <SocialLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialLink>
          </SocialLinks>
        </SocialSection>
      </MainContent>

      <BottomBar />
    </PageContainer>
  );
};

export default Contact;
