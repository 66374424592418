import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';

const PrivacyContainer = styled.div`
  padding: 60px 20px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

const Header = styled.h1`
  font-size: 3rem;
  color: #1e293b;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.3rem;
  color: #475569;
  line-height: 1.8;
  margin-bottom: 50px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  margin: 50px 0;
`;

const SectionTitle = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 25px;
  color: #0ea5e9;
  font-weight: 600;
`;

const SectionContent = styled.p`
  font-size: 1.2rem;
  color: #475569;
  line-height: 1.8;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const FormContainer = styled.div`
  background: #f8fafc;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  resize: none;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #e63946;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d62828;
  }
`;

const AccountDeletion: React.FC = () => {
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <>
      <Helmet>
        <title>Account Deletion - Ressydent</title>
        <meta name="description" content="Learn how to request account deletion and understand the implications of removing your data from Ressydent." />
        <meta name="keywords" content="account deletion, data removal, Ressydent security, user privacy" />
      </Helmet>
      <PrivacyContainer>
        <TopNavBar />
        <Header>Account Deletion</Header>
        <Description>
          At Ressydent, we respect your right to data privacy. This page provides details on how to request account deletion and what happens to your data.
        </Description>
        <Section>
          <SectionTitle>Account Deletion Process</SectionTitle>
          <SectionContent>
            To delete your account, submit a request through our platform. Your data, including personal details and transaction history, will be permanently removed.
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>What Happens After Deletion</SectionTitle>
          <SectionContent>
            Once your account is deleted, all personal data will be erased, except for information required for compliance with legal or regulatory obligations.
          </SectionContent>
        </Section>
        <FormContainer>
          {submitted ? (
            <p>Your account deletion request has been submitted. We will process it shortly.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email:</label>
              <Input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="reason">Reason for deletion:</label>
              <TextArea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
                rows={4}
              />
              <Button type="submit">Submit Request</Button>
            </form>
          )}
        </FormContainer>
        <BottomBar />
      </PrivacyContainer>
    </>
  );
};

export default AccountDeletion;