import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGooglePlay, FaApple } from 'react-icons/fa';

const BottomBarContainer = styled.footer`
  padding: 40px 20px;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
`;

const BottomContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SectionTitle = styled.h3`
  font-size: 1.2rem;
  color: #0ea5e9;
  margin: 0 0 10px 0;
`;

const FooterLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    color: #0ea5e9;
    transform: translateX(5px);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
`;

const SocialLink = styled.a`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: #0ea5e9;
    transform: translateY(-3px);
  }
`;

const ContactInfo = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 5px 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BottomSection = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Copyright = styled.p`
  margin: 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
`;

const AppStoreSection = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const StoreButton = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const BottomBar: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <BottomBarContainer>
      <BottomContent>
        <FooterSection>
          <SectionTitle>Company</SectionTitle>
          <FooterLink to="/about">About Us</FooterLink>
          <FooterLink to="/contact">Contact Us</FooterLink>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink to="/account-deletion">Account Deletion</FooterLink>
        </FooterSection>

        <FooterSection>
          <SectionTitle>Contact</SectionTitle>
          <ContactInfo>Email: support@ressytech.com</ContactInfo>
          <ContactInfo>Phone: +234 903 119 4921</ContactInfo>
          <SocialLinks>
            <SocialLink href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </SocialLink>
            <SocialLink href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialLink>
            <SocialLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialLink>
          </SocialLinks>
        </FooterSection>

        <FooterSection>
          <SectionTitle>Download Our App</SectionTitle>
          <AppStoreSection>
            <StoreButton href="https://play.google.com/store/apps/details?id=com.Ressydent&hl=en" target="_blank" rel="noopener noreferrer">
              <FaGooglePlay /> Google Play
            </StoreButton>
            <StoreButton href="https://apps.apple.com/ng/app/ressydent/id6741027949" target="_blank" rel="noopener noreferrer">
              <FaApple /> App Store
            </StoreButton>
          </AppStoreSection>
        </FooterSection>
      </BottomContent>

      <BottomSection>
        <Copyright>
          &copy; {currentYear} Ressydent. Powered by Ressytech Limited. All rights reserved.
        </Copyright>
      </BottomSection>
    </BottomBarContainer>
  );
};

export default BottomBar;
