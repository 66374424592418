import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { verifyAndVendPayment } from '../../services/PaymentVerificationService';

const ReverifyPayment: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    meterNumber: '',
    transactionDate: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await verifyAndVendPayment(formData);
      setSuccess(response.message || 'Payment verification successful!');
      // Clear form after success
      setFormData({
        meterNumber: '',
        transactionDate: '',
      });
    } catch (err: any) {
      setError(err.response?.data?.message || 'An error occurred while verifying the payment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
        <Typography variant="h5" gutterBottom>
          Reverify Payment
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Meter Number"
            name="meterNumber"
            value={formData.meterNumber}
            onChange={handleInputChange}
            margin="normal"
            required
          />

          <TextField
            fullWidth
            type="date"
            label="Transaction Date"
            name="transactionDate"
            value={formData.transactionDate}
            onChange={handleInputChange}
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ flex: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Verify Payment'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/admin')}
              sx={{ flex: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default ReverifyPayment;
