import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  Box,
  Typography,
  Chip,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { FaWater, FaSearch, FaDownload } from 'react-icons/fa';
import { fetchWaterDepositHistory } from '../../../services/DepositService';
import { getResidents, getManagerResidents } from '../../../services/ResidentService';
import { ResidentResponse, Resident } from '../../../types/Resident';
import { DepositTransaction } from '../../../types/reports';
import { useEstates } from '../../../hooks/useEstates';
import { getCurrentAdmin, isSuperAdmin } from '../../../services/AdminService';
import { AdminData } from '../../../types/admin';
import { styled as muiStyled } from '@mui/material/styles';

const Container = styled(Paper)`
  padding: 20px;
  margin: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const Title = styled(Typography)`
  font-size: 24px;
  margin-bottom: 20px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: #f8fafc;
    font-weight: 600;
    color: #1a1a2e;
  }
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f8fafc;
  }
`;

const StatusChip = styled(Chip)<{ $status: string }>`
  background-color: ${props => {
    switch (props.$status.toLowerCase()) {
      case 'completed':
        return '#4caf50';
      case 'pending':
        return '#ff9800';
      case 'failed':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }};
  color: white;
  font-size: 0.75rem;
  height: 24px;
`;

const FilterCard = styled(Card)`
  margin-bottom: 20px;
`;

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format(date);
};

const formatCurrency = (amount: string | number) => {
  const value = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(value);
};

const StyledDateInput = styled(TextField)`
  & .MuiInputBase-input {
    padding: 8px 12px;
  }
`;

const formatPaymentMonth = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    month: 'short',
    year: 'numeric'
  }).format(date);
};

interface WaterDepositReportProps {
  admin: AdminData;
}

export const WaterDepositReport: React.FC<WaterDepositReportProps> = ({ admin }) => {
  // Get first day and last day of current month
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
  const [transactions, setTransactions] = useState<DepositTransaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [residents, setResidents] = useState<Resident[]>([]);
  const [loadingResidents, setLoadingResidents] = useState(false);
  const [filters, setFilters] = useState({
    startDate: firstDayOfMonth.toISOString().split('T')[0],
    endDate: lastDayOfMonth.toISOString().split('T')[0],
    estate_id: '',
    user_id: '',
    status: '',
  });

  const { estates } = useEstates();

  const fetchResidentsList = async () => {
    setLoadingResidents(true);
    try {
      let response: ResidentResponse;
      if (admin.adminType === 'Super Admin') {
        response = await getResidents();
      } else {
        response = await getManagerResidents(admin.id);
      }
      setResidents(response.data.users);
    } catch (error) {
      console.error('Error fetching residents:', error);
    } finally {
      setLoadingResidents(false);
    }
  };

  useEffect(() => {
    fetchResidentsList();
  }, []);

  const fetchTransactions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchWaterDepositHistory({
        page: page + 1,
        limit: rowsPerPage,
        startDate: filters.startDate || firstDayOfMonth.toISOString().split('T')[0],
        endDate: filters.endDate || lastDayOfMonth.toISOString().split('T')[0],
        estate_id: filters.estate_id ? Number(filters.estate_id) : undefined,
        user_id: filters.user_id ? Number(filters.user_id) : undefined,
        status: filters.status || undefined,
        manager_id: admin.adminType === 'Admin' ? admin.id : undefined,
      });

      console.log('API Response:', response);

      if (response.status && response.data?.chargesData) {
        setTransactions(response.data.chargesData);
        setTotalRows(response.data.chargesData.length);
      } else {
        setTransactions([]);
        setTotalRows(0);
        setError(response.message || 'Failed to fetch transactions');
      }
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setTransactions([]);
      setTotalRows(0);
      setError('Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchTransactions();
  };

  const handleExport = () => {
    // Implement export functionality
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  // Filter estates based on admin role
  const filteredEstates = admin.adminType === 'Super Admin' 
    ? estates 
    : estates.filter(estate => Number(estate.manager_user_id) === admin.id);

  return (
    <Container elevation={0}>
      <Header>
        <Title variant="h4">
          <FaWater style={{ marginRight: '8px' }} />
          Water Deposit Report
        </Title>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaDownload />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Header>

      <FilterCard>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {admin.adminType === 'Super Admin' && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Estate</InputLabel>
                  <Select
                    value={filters.estate_id || ''}
                    onChange={(e) => handleFilterChange('estate_id', e.target.value)}
                    label="Estate"
                  >
                    <MenuItem value="">All Estates</MenuItem>
                    {filteredEstates.map((estate) => (
                      <MenuItem key={estate.id} value={estate.id}>
                        {estate.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>Resident</InputLabel>
                <Select
                  value={filters.user_id || ''}
                  onChange={(e) => handleFilterChange('user_id', e.target.value)}
                  label="Resident"
                  disabled={loadingResidents}
                >
                  <MenuItem value="">All Residents</MenuItem>
                  {residents.map((resident) => (
                    <MenuItem key={resident.id} value={resident.id}>
                      {resident.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledDateInput
                fullWidth
                size="small"
                type="date"
                value={filters.startDate}
                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Start Date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledDateInput
                fullWidth
                size="small"
                type="date"
                value={filters.endDate}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="End Date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                fullWidth
                size="small"
                label="Status"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                SelectProps={{ native: true }}
              >
                <option value="">All Status</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSearch}
                startIcon={<FaSearch />}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </FilterCard>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Payment Month</TableCell>
              <TableCell>Amount</TableCell>
              {/* <TableCell>Meter Number</TableCell> */}
              <TableCell>House Number</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ color: 'error.main' }}>
                  {error}
                </TableCell>
              </TableRow>
            ) : transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No transactions found
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>{formatPaymentMonth(transaction.payment_month)}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  {/* <TableCell>{transaction.meter_number}</TableCell> */}
                  <TableCell>{transaction.estate_user.housenum}</TableCell>
                  <TableCell>{transaction.user?.name || 'N/A'}</TableCell>
                  <TableCell>{new Date(transaction.created_at).toLocaleString()}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Container>
  );
};

export default WaterDepositReport; 