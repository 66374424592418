import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';

const PrivacyContainer = styled.div`
  padding: 60px 20px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

const Header = styled.h1`
  font-size: 3rem;
  color: #1e293b;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.3rem;
  color: #475569;
  line-height: 1.8;
  margin-bottom: 50px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  margin: 50px 0;
  text-align: left;
`;

const SectionTitle = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 25px;
  color: #0ea5e9;
  font-weight: 600;
`;

const SectionContent = styled.p`
  font-size: 1.2rem;
  color: #475569;
  line-height: 1.8;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ressydent</title>
        <meta name="description" content="Understand how we collect, use, and protect your data at Ressydent. Your privacy is our priority." />
        <meta name="keywords" content="privacy policy, data protection, Ressydent security, user privacy" />
      </Helmet>
      <PrivacyContainer>
        <TopNavBar />
        <Header>Privacy Policy</Header>
        <Description>Effective Date: February 17, 2025</Description>
        <Section>
          <SectionTitle>Introduction</SectionTitle>
          <SectionContent>
            Welcome to our Privacy Policy page! When you use our web services, you trust us with your information. This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it. This is important; we hope you will take time to read it carefully.
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Information We Collect</SectionTitle>
          <SectionContent>
            We collect information to provide better services to all our users. We collect information in the following ways:
            <ul>
              <li>Information you give us: Our services require you to sign up for an account, providing personal details like name, email, and telephone number.</li>
              <li>Information we get from your use of our services: We collect information on how you interact with our services, including website visits and content engagement.</li>
            </ul>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>How We Use Information We Collect</SectionTitle>
          <SectionContent>
            We use the information we collect from all our services to provide, maintain, protect, and improve them, to develop new ones, and to protect our users. We also use this information to offer you tailored content.
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Information We Share</SectionTitle>
          <SectionContent>
            We do not share personal information with companies, organizations, and individuals outside of our company unless one of the following circumstances applies:
            <ul>
              <li><strong>With your consent:</strong> We will share personal information when we have your explicit permission.</li>
              <li><strong>For legal reasons:</strong> We will share information if we have a good-faith belief that it is necessary to meet legal obligations, enforce policies, or prevent fraud and security threats.</li>
            </ul>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Changes</SectionTitle>
          <SectionContent>
            Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any changes on this page, and if significant, we will notify you directly.
          </SectionContent>
        </Section>
        <BottomBar />
      </PrivacyContainer>
    </>
  );
};

export default PrivacyPolicy;