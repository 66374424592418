import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  TextField,
  IconButton,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  CircularProgress,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FaSearch, FaEye, FaCalendarAlt, FaPowerOff, FaUsers, FaUserCheck, FaUserPlus, FaBuilding, FaExclamationTriangle } from "react-icons/fa";
import { getResidents, getManagerResidents, updateResidentStatus, updateResidentDueDate, updateServiceFeeDueDate } from "../../services/ResidentService";
import { getCurrentAdmin } from "../../services/AdminService";
import { isSuperAdmin } from "../../types/admin";
import { EstateUser, Resident } from "../../types/Resident";

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StatsGrid = styled(Grid)`
  margin-bottom: 24px;
`;

const StatsCard = styled(Paper)`
  padding: 24px;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const StatsCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 2;
`;

const StatsIcon = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatsInfo = styled.div`
  flex: 1;
`;

const StatsNumber = styled(Typography)`
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.8) !important;
`;

const StatsLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 0.9rem !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
`;

const SearchContainer = styled(Paper)`
  padding: 16px;
  margin-bottom: 24px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StatusChip = styled(Chip)<{ $active: boolean }>`
  background-color: ${props => props.$active ? '#4caf50' : '#f44336'} !important;
  color: white !important;
`;

const DetailSection = styled.div`
  margin-bottom: 24px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailSectionTitle = styled.h3`
  color: #1a1a2e;
  font-size: 1.1rem;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #eee;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: #666;
`;

const DetailValue = styled.span`
  color: #333;
  text-align: right;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ConfirmationDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 400px;
  }
`;

const ConfirmationContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
`;

const WarningIcon = styled(FaExclamationTriangle)`
  color: #f44336;
  font-size: 48px;
  margin-bottom: 16px;
`;

const ServiceFeeForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const ResidentManagement: React.FC = () => {
  const [residents, setResidents] = useState<Resident[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState<'name' | 'houseNumber' | 'meterNumber'>('name');
  const [selectedResident, setSelectedResident] = useState<Resident | null>(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [dueDateDialog, setDueDateDialog] = useState(false);
  const [newDueDate, setNewDueDate] = useState("");
  const [showStatusConfirm, setShowStatusConfirm] = useState(false);
  const [showServiceFeeConfirm, setShowServiceFeeConfirm] = useState(false);
  const [selectedResidentForStatus, setSelectedResidentForStatus] = useState<Resident | null>(null);
  const [serviceFeeData, setServiceFeeData] = useState({
    no_of_month: 1,
    payment_date: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  const admin = getCurrentAdmin();
  const isSuper = admin ? isSuperAdmin(admin) : false;

  const fetchData = async () => {
    try {
      setLoading(true);
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        search: search || undefined,
        searchBy: searchBy || undefined
      };

      const response = isSuper
        ? await getResidents(params)
        : await getManagerResidents(admin!.id, params);

      setResidents(response.data.users);
      setTotal(response.data.pagination.total);
      setTotalPages(response.data.pagination.totalPages);

      // Update stats from the response
      setStats({
        total: response.data.pagination.total,
        active: response.data.stats?.activeCount || 0,
        new: response.data.stats?.newCount || 0,
        estates: response.data.stats?.estateCount || 0
      });
    } catch (err) {
      console.error('Error fetching residents:', err);
    } finally {
      setLoading(false);
    }
  };

  // Add stats state
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    new: 0,
    estates: 0
  });

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, search, searchBy]);

  const handleSearch = () => {
    setPage(0); // Reset to first page when searching
    fetchData();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = async (resident: Resident) => {
    setSelectedResidentForStatus(resident);
    setShowStatusConfirm(true);
  };

  const confirmStatusChange = async () => {
    if (!selectedResidentForStatus) return;

    try {
      setProcessing(true);
      const newStatus = selectedResidentForStatus.active === true;
      await updateResidentStatus(selectedResidentForStatus.id, !newStatus);
      setSuccessMessage(`Resident ${newStatus ? 'disabled' : 'enabled'} successfully`);
      fetchData();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to update status');
    } finally {
      setProcessing(false);
      setShowStatusConfirm(false);
      setSelectedResidentForStatus(null);
    }
  };

  const handleDueDateUpdate = async () => {
    if (!selectedResident || !newDueDate) return;

    try {
      setProcessing(true);
      await updateResidentDueDate(selectedResident.id, newDueDate);
      setDueDateDialog(false);
      setNewDueDate("");
      fetchData();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to update due date');
    } finally {
      setProcessing(false);
    }
  };

  const handleServiceFeeUpdate = async () => {
    if (!selectedResident || !serviceFeeData.payment_date) return;

    try {
      setProcessing(true);
      await updateServiceFeeDueDate({
        user_id: selectedResident.id,
        meternumber: selectedResident.estateusers.meternumber,
        amount: 0, // This will be handled by the backend
        phonenumber: selectedResident.estateusers.phonenumber,
        email: selectedResident.email,
        estate_id: selectedResident.estateusers.estate_id,
        no_of_month: serviceFeeData.no_of_month,
        payment_date: serviceFeeData.payment_date,
      });
      setSuccessMessage('Service fee due date updated successfully');
      setDueDateDialog(false);
      setServiceFeeData({ no_of_month: 1, payment_date: "" });
      fetchData();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to update service fee due date');
    } finally {
      setProcessing(false);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <Container>
      <Header>
        <Typography variant="h5">Resident Management</Typography>
      </Header>

      <StatsGrid container spacing={3}>
        <Grid item xs={12} sm={isSuper ? 6 : 12} md={isSuper ? 3 : 12}>
          <StatsCard elevation={0}>
            <StatsCardContent>
              <StatsIcon>
                <FaUsers size={32} />
              </StatsIcon>
              <StatsInfo>
                <StatsNumber>{stats.total}</StatsNumber>
                <StatsLabel>
                  Total Number of{'\n'}
                  Registered Residents
                </StatsLabel>
              </StatsInfo>
            </StatsCardContent>
          </StatsCard>
        </Grid>
        
        {isSuper && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard elevation={0}>
                <StatsCardContent>
                  <StatsIcon>
                    <FaUserCheck size={32} />
                  </StatsIcon>
                  <StatsInfo>
                    <StatsNumber>{stats.active}</StatsNumber>
                    <StatsLabel>
                      Currently Active{'\n'}
                      Residents
                    </StatsLabel>
                  </StatsInfo>
                </StatsCardContent>
              </StatsCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard elevation={0}>
                <StatsCardContent>
                  <StatsIcon>
                    <FaUserPlus size={32} />
                  </StatsIcon>
                  <StatsInfo>
                    <StatsNumber>{stats.new}</StatsNumber>
                    <StatsLabel>
                      New Resident{'\n'}
                      Registrations
                    </StatsLabel>
                  </StatsInfo>
                </StatsCardContent>
              </StatsCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard elevation={0}>
                <StatsCardContent>
                  <StatsIcon>
                    <FaBuilding size={32} />
                  </StatsIcon>
                  <StatsInfo>
                    <StatsNumber>{stats.estates}</StatsNumber>
                    <StatsLabel>
                      Total Number of{'\n'}
                      Active Estates
                    </StatsLabel>
                  </StatsInfo>
                </StatsCardContent>
              </StatsCard>
            </Grid>
          </>
        )}
      </StatsGrid>

      <SearchContainer elevation={0}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
        <TextField
              fullWidth
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
        <TextField
              select
              fullWidth
              label="Search By"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value as 'name' | 'houseNumber' | 'meterNumber')}
              size="small"
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="houseNumber">House Number</MenuItem>
              <MenuItem value="meterNumber">Meter Number</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
      <Button
        variant="contained"
              onClick={handleSearch}
              startIcon={<FaSearch />}
              fullWidth
            >
              Search
      </Button>
          </Grid>
        </Grid>
      </SearchContainer>

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>House Number</TableCell>
              <TableCell>Estate</TableCell>
              <TableCell>Meter Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {residents.map((resident) => (
                <TableRow key={resident.id} hover>
                <TableCell>{resident.name}</TableCell>
                <TableCell>{resident.estateusers?.housenum || "N/A"}</TableCell>
                <TableCell>{resident.estateusers?.estates?.name || "N/A"}</TableCell>
                <TableCell>{resident.estateusers?.meternumber || "N/A"}</TableCell>
                <TableCell>
                  <StatusChip
                    label={resident.active ? 'Active' : 'Inactive'}
                    $active={resident.active ? true : false}
                    size="small"
                    />
                  </TableCell>
                <TableCell align="center">
                  <ActionButtonsContainer>
                    <IconButton
                      size="small"
                      onClick={() => {
                      if (!resident.estateusers) {
                        alert("Estate details have not been configured for this resident");
                        return;
                      }
                      setSelectedResident(resident);
                      }}
                      title="View Details"
                    >
                      <FaEye />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedResident(resident);
                        setDueDateDialog(true);
                      }}
                      title="Update Due Date"
                    >
                      <FaCalendarAlt />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleStatusChange(resident)}
                      title={resident.active ? 'Disable' : 'Enable'}
                      color={resident.active ? 'error' : 'success'}
                    >
                      <FaPowerOff />
                    </IconButton>
                  </ActionButtonsContainer>
                  </TableCell>
                </TableRow>
              ))}
            {loading && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            )}
            {!loading && residents.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No residents found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 50]}
      />

      {/* View Details Dialog */}
      <Dialog
        open={!!selectedResident && !dueDateDialog}
        onClose={() => setSelectedResident(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Resident Details</DialogTitle>
        <DialogContent dividers>
          {selectedResident && (
            <>
              <DetailSection>
                <DetailSectionTitle>Basic Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Name:</DetailLabel>
                  <DetailValue>{selectedResident.name}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Email:</DetailLabel>
                  <DetailValue>{selectedResident.email}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Status:</DetailLabel>
                  <DetailValue>
                    <StatusChip
                      label={selectedResident.active ? 'Active' : 'Inactive'}
                      $active={selectedResident.active}
                      size="small"
                    />
                  </DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Join Date:</DetailLabel>
                  <DetailValue>{formatDate(selectedResident.created_at)}</DetailValue>
                </DetailRow>
              </DetailSection>

              <DetailSection>
                <DetailSectionTitle>Estate Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Estate:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.estates?.name || "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Address:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.estates?.address || "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>House Number:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.housenum || "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Meter Number:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.meternumber || "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Phone Number:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.phonenumber || "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Service Fee Due Date:</DetailLabel>
                  <DetailValue>{selectedResident.serviceFeeDueDate ? formatDate(selectedResident.serviceFeeDueDate) : "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Water Deposit Due  Date:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.last_water_payment ? formatDate(selectedResident.estateusers.last_water_payment) : "N/A"}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Diesel Deposit Due  Date:</DetailLabel>
                  <DetailValue>{selectedResident.estateusers.last_diesel_payment ? formatDate(selectedResident.estateusers.last_diesel_payment) : "N/A"}</DetailValue>
                </DetailRow>
              </DetailSection>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedResident(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Update Due Date Dialog */}
      <Dialog
        open={dueDateDialog}
        onClose={() => {
          setDueDateDialog(false);
          setNewDueDate("");
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Update Due Date</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ pt: 2 }}>
            <TextField
              type="date"
              label="New Due Date"
              value={newDueDate}
              onChange={(e) => setNewDueDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
              <Button
            onClick={() => {
              setDueDateDialog(false);
              setNewDueDate("");
            }}
              >
                Cancel
              </Button>
          <LoadingButton
            onClick={handleDueDateUpdate}
            loading={processing}
            variant="contained"
            disabled={!newDueDate}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Status Change Confirmation Dialog */}
      <ConfirmationDialog
        open={showStatusConfirm}
        onClose={() => setShowStatusConfirm(false)}
      >
        <DialogTitle>Confirm Status Change</DialogTitle>
        <ConfirmationContent>
          <WarningIcon />
          <Typography variant="h6" gutterBottom>
            Are you sure you want to {selectedResidentForStatus?.active ? 'disable' : 'enable'} this resident?
              </Typography>
          <Typography color="textSecondary">
            This action cannot be undone.
              </Typography>
        </ConfirmationContent>
        <DialogActions>
          <Button onClick={() => setShowStatusConfirm(false)}>Cancel</Button>
          <LoadingButton
            onClick={confirmStatusChange}
            loading={processing}
            color="error"
            variant="contained"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </ConfirmationDialog>

      {/* Service Fee Update Dialog */}
      <Dialog
        open={dueDateDialog}
        onClose={() => {
          setDueDateDialog(false);
          setServiceFeeData({ no_of_month: 1, payment_date: "" });
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update Service Fee Due Date</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}
          <ServiceFeeForm>
            <TextField
              type="number"
              label="Number of Months"
              value={serviceFeeData.no_of_month}
              onChange={(e) => setServiceFeeData(prev => ({
                ...prev,
                no_of_month: parseInt(e.target.value)
              }))}
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
            />
            <TextField
              type="date"
              label="Payment Date"
              value={serviceFeeData.payment_date}
              onChange={(e) => setServiceFeeData(prev => ({
                ...prev,
                payment_date: e.target.value
              }))}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ServiceFeeForm>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDueDateDialog(false);
              setServiceFeeData({ no_of_month: 1, payment_date: "" });
            }}
          >
            Cancel
            </Button>
          <LoadingButton
            onClick={handleServiceFeeUpdate}
            loading={processing}
            variant="contained"
            disabled={!serviceFeeData.payment_date}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ResidentManagement;
