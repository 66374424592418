import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  IconButton,
  Box,
  Chip,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllEstates } from '../../services/EstateService';
import { Estate } from '../../types/Estate';
import { FaBuilding, FaEdit, FaSearch } from 'react-icons/fa';

const Container = styled(Paper)`
  padding: 24px;
  margin: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #1a1a2e;
  font-weight: 600;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  max-width: 500px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: #f8fafc;
    font-weight: 600;
    color: #1a1a2e;
  }
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f8fafc;
  }
`;

const ServiceChip = styled(Chip)<{ $active: boolean }>`
  background-color: ${props => props.$active ? '#4caf50' : '#ff5252'};
  color: white;
  font-size: 0.75rem;
  height: 24px;
  opacity: ${props => props.$active ? 1 : 0.7};
  
  &:hover {
    opacity: 1;
    background-color: ${props => props.$active ? '#45a049' : '#ff1744'};
  }
`;

const ActionButton = styled(Button)`
  text-transform: none;
  border-radius: 8px;
  padding: 6px 16px;
`;

const formatCurrency = (amount: string | number) => {
  const value = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(value);
};

const EstateManagement: React.FC = () => {
  const [estates, setEstates] = useState<Estate[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEstates();
  }, []);

  const fetchEstates = async () => {
    setLoading(true);
    try {
      const response = await getAllEstates();
      if (response) {
        setEstates(response);
      }
    } catch (error) {
      console.error('Error fetching estates:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEstateClick = (estateId: number) => {
    navigate(`/admin/estates/${estateId}`);
  };

  const handleAddEstateClick = () => {
    navigate('/admin/estates/new');
  };

  const filteredEstates = estates.filter(estate =>
    estate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    estate.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container elevation={0}>
      <Header>
        <Title variant="h4">
          <FaBuilding size={24} />
          Estate Management
        </Title>
        <SearchContainer>
          <TextField
            fullWidth
            placeholder="Search estates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: <FaSearch style={{ marginRight: 8, color: '#666' }} />,
            }}
          />
          <ActionButton
            variant="contained"
            color="primary"
            onClick={handleAddEstateClick}
            startIcon={<FaBuilding />}
          >
            Add Estate
          </ActionButton>
        </SearchContainer>
      </Header>

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Estate Name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Service Charge</StyledTableCell>
              <StyledTableCell>Services</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEstates
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((estate) => (
                <StyledTableRow key={estate.id}>
                  <TableCell>{estate.name}</TableCell>
                  <TableCell>{estate.address}</TableCell>
                  <TableCell>{formatCurrency(estate.service_charge)}</TableCell>
                  <TableCell>
                    <Box display="flex" gap={1} flexWrap="wrap">
                      <Tooltip title={estate.vend_status ? "Power vending enabled" : "Power vending disabled"}>
                        <div>
                          <ServiceChip
                            label="Power"
                            size="small"
                            $active={estate.vend_status}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title={estate.service_charge_status 
                        ? `Service charge: ${formatCurrency(estate.service_charge)}/month` 
                        : "Service charge disabled"}>
                        <div>
                          <ServiceChip
                            label="Service Fee"
                            size="small"
                            $active={estate.service_charge_status}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title={estate.water_fee_status 
                        ? `Water deposit: ${formatCurrency(estate.water_deposit)}` 
                        : "Water deposit disabled"}>
                        <div>
                          <ServiceChip
                            label="Water"
                            size="small"
                            $active={estate.water_fee_status}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title={estate.diesel_fee_status 
                        ? `Diesel fee: ${formatCurrency(estate.diesel_deposit)}` 
                        : "Diesel fee disabled"}>
                        <div>
                          <ServiceChip
                            label="Diesel"
                            size="small"
                            $active={estate.diesel_fee_status}
                          />
                        </div>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      onClick={() => handleEstateClick(estate.id)}
                      size="small"
                    >
                      <FaEdit />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        component="div"
        count={filteredEstates.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Container>
  );
};

export default EstateManagement;
