import React from 'react';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';
import { FaShieldAlt, FaRocket, FaUsers, FaHandshake, FaBolt, FaTint, FaUserFriends, FaMoneyBill } from 'react-icons/fa';

const PageContainer = styled.div`
  font-family: 'Inter', sans-serif;
  padding-top: 70px; // Account for fixed navbar
`;

const HeroSection = styled.div`
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SubHeader = styled.p`
  font-size: 1.4rem;
  line-height: 1.8;
  margin-bottom: 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.9);
`;

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
`;

const Section = styled.section`
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #1a1a2e;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const ValueCard = styled.div`
  background: white;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
`;

const ValueIcon = styled.div`
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: white;
  font-size: 24px;
`;

const ValueTitle = styled.h3`
  font-size: 1.8rem;
  color: #1a1a2e;
  margin-bottom: 15px;
  font-weight: 600;
`;

const ValueDescription = styled.p`
  font-size: 1.1rem;
  color: #475569;
  line-height: 1.6;
`;

const ServicesList = styled.div`
  background: #f8fafc;
  padding: 30px;
  border-radius: 16px;
  margin-top: 30px;
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e2e8f0;

  &:last-child {
    border-bottom: none;
  }
`;

const ServiceIcon = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: white;
  font-size: 20px;
`;

const ServiceText = styled.p`
  font-size: 1.2rem;
  color: #1a1a2e;
  margin: 0;
`;

const About: React.FC = () => {
  return (
    <PageContainer>
      <TopNavBar />
      
      <HeroSection>
        <HeroContent>
          <Header>About Ressydent</Header>
          <SubHeader>
            Transforming estate living through innovative technology solutions that empower residents
            and streamline facility management.
          </SubHeader>
        </HeroContent>
      </HeroSection>

      <MainContent>
        <Section>
          <SectionTitle>Our Mission</SectionTitle>
          <SubHeader style={{ color: '#475569', textAlign: 'center' }}>
            To revolutionize estate living by providing cutting-edge solutions that enhance the quality of life
            for residents while simplifying facility management operations.
          </SubHeader>
          
          <ValuesGrid>
            <ValueCard>
              <ValueIcon>
                <FaShieldAlt />
              </ValueIcon>
              <ValueTitle>Security</ValueTitle>
              <ValueDescription>
                We prioritize the security of our users' data and transactions, implementing
                industry-leading protection measures.
              </ValueDescription>
            </ValueCard>

            <ValueCard>
              <ValueIcon>
                <FaRocket />
              </ValueIcon>
              <ValueTitle>Innovation</ValueTitle>
              <ValueDescription>
                Continuously evolving our platform with the latest technology to provide
                the best possible user experience.
              </ValueDescription>
            </ValueCard>

            <ValueCard>
              <ValueIcon>
                <FaUsers />
              </ValueIcon>
              <ValueTitle>Community</ValueTitle>
              <ValueDescription>
                Building strong communities by facilitating seamless interaction between
                residents and estate management.
              </ValueDescription>
            </ValueCard>

            <ValueCard>
              <ValueIcon>
                <FaHandshake />
              </ValueIcon>
              <ValueTitle>Reliability</ValueTitle>
              <ValueDescription>
                Delivering consistent, dependable service that our users can count on
                for their daily estate management needs.
              </ValueDescription>
            </ValueCard>
          </ValuesGrid>
        </Section>

        <Section>
          <SectionTitle>Our Services</SectionTitle>
          <ServicesList>
            <ServiceItem>
              <ServiceIcon>
                <FaBolt />
              </ServiceIcon>
              <ServiceText>Seamless electricity vending with instant token delivery</ServiceText>
            </ServiceItem>
            <ServiceItem>
              <ServiceIcon>
                <FaMoneyBill />
              </ServiceIcon>
              <ServiceText>Convenient service charge payments and tracking</ServiceText>
            </ServiceItem>
            <ServiceItem>
              <ServiceIcon>
                <FaTint />
              </ServiceIcon>
              <ServiceText>Efficient water and diesel deposit management</ServiceText>
            </ServiceItem>
            <ServiceItem>
              <ServiceIcon>
                <FaUserFriends />
              </ServiceIcon>
              <ServiceText>Secure visitor access management system</ServiceText>
            </ServiceItem>
          </ServicesList>
        </Section>
      </MainContent>

      <BottomBar />
    </PageContainer>
  );
};

export default About;