import axios from 'axios';

const client = axios.create({
    // baseURL: 'http://localhost:3001',
    baseURL: 'https://api.ressydent.com'
});

// Add a request interceptor to automatically add the auth token
client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle token expiration
client.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Clear local storage and redirect to login
            localStorage.removeItem('adminData');
            localStorage.removeItem('authToken');
            window.location.href = '/admin/login';
        }
        return Promise.reject(error);
    }
);

export default client;