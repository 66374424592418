import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  FaUsers, 
  FaFileAlt, 
  FaBuilding, 
  FaRegistered,
  FaChevronDown,
  FaChevronRight,
  FaWallet,
  FaReceipt,
  FaMoneyBill,
  FaTint,
  FaGasPump,
  FaUserFriends,
  FaMoneyCheck,
  FaBars,
  FaTimes,
  FaSignOutAlt,
  FaBolt,
  FaList,
  FaChartLine,
  FaBell,
  FaExclamationTriangle,
  FaUserPlus,
  FaWater,
  FaFileInvoiceDollar,
  FaSync,
  FaMoneyBillWave
} from 'react-icons/fa';
import { getCurrentAdmin, logout } from '../../services/AdminService';
import { getResidents, getManagerResidents } from '../../services/ResidentService';
import { fetchPendingUsers } from '../../services/ResidentService';
import { fetchVendTransactions } from '../../services/VendService';
import { fetchWalletFunding } from '../../services/WalletFundingService';
import { AdminData, isSuperAdmin } from '../../types/admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  Grid,
  Paper,
  Typography,
  Box,
  Chip,
  IconButton,
  Badge,
  CircularProgress,
} from '@mui/material';

const DashboardContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f4f6f8;
`;

const Sidebar = styled.div<{ $isOpen: boolean }>`
  width: ${props => props.$isOpen ? '280px' : '70px'};
  background-color: #1a1a2e;
  color: white;
  transition: width 0.3s ease;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    left: ${props => props.$isOpen ? '0' : '-280px'};
    width: 280px;
    height: 100vh;
    z-index: 1000;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const LogoImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`;

const Logo = styled.h2<{ $isOpen: boolean }>`
  margin: 0;
  font-size: 1.2rem;
  white-space: nowrap;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  color: white;
  font-weight: 700;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MenuToggle = styled.button<{ $isOpen: boolean }>`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  position: ${props => props.$isOpen ? 'relative' : 'fixed'};
  left: ${props => props.$isOpen ? 'auto' : '20px'};
  top: ${props => props.$isOpen ? 'auto' : '20px'};
  z-index: 1001;

  &:hover {
    opacity: 0.8;
  }
`;

const NavSection = styled.div`
  padding: 10px;
`;

const NavItem = styled(Link)<{ $active?: boolean; $isOpen?: boolean }>`
  text-decoration: none;
  color: ${props => props.$active ? '#fff' : 'rgba(255, 255, 255, 0.7)'};
  padding: 12px 15px;
  margin: 5px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: ${props => props.$active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  svg {
    margin-right: ${props => props.$isOpen ? '12px' : '0'};
    font-size: 1.1rem;
  }

  span {
    opacity: ${props => props.$isOpen ? 1 : 0};
    transition: opacity 0.3s ease;
  }
`;

const SubNavItem = styled(NavItem)`
  padding-left: 45px;
  font-size: 0.9rem;
`;

const NavGroup = styled.div<{ $isOpen: boolean }>`
  margin: 5px 0;
`;

const NavGroupHeader = styled.div<{ $active?: boolean; $isOpen?: boolean }>`
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.$active ? '#fff' : 'rgba(255, 255, 255, 0.7)'};
  cursor: pointer;
  border-radius: 8px;
  background-color: ${props => props.$active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  svg:last-child {
    opacity: ${props => props.$isOpen ? 1 : 0};
    transition: opacity 0.3s ease;
  }
`;

const NavGroupTitle = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    opacity: ${props => props.$isOpen ? 1 : 0};
    transition: opacity 0.3s ease;
  }
`;

const SubNav = styled.div<{ $isOpen: boolean }>`
  max-height: ${props => props.$isOpen ? '500px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const ContentArea = styled.div<{ $sidebarOpen: boolean }>`
  flex: 1;
  padding: 20px;
  margin-left: ${props => props.$sidebarOpen ? '0' : '0'};
  transition: margin-left 0.3s ease;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const MobileOverlay = styled.div<{ $isOpen: boolean }>`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: ${props => props.$isOpen ? 1 : 0};
    visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
`;

const AdminInfo = styled.div`
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
`;

const AdminName = styled.h3<{ $isOpen: boolean }>`
  margin: 0;
  font-size: 1rem;
  color: white;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  white-space: nowrap;
`;

const AdminRole = styled.p<{ $isOpen: boolean }>`
  margin: 5px 0 0;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  white-space: nowrap;
`;

const LogoutButton = styled.button<{ $isOpen: boolean }>`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  padding: 12px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  svg {
    margin-right: ${props => props.$isOpen ? '12px' : '0'};
  }

  span {
    opacity: ${props => props.$isOpen ? 1 : 0};
    transition: opacity 0.3s ease;
  }
`;

const WelcomeSection = styled.div`
  padding: 24px;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 16px;
  margin-bottom: 24px;
  color: white;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const WelcomeContent = styled.div`
  position: relative;
  z-index: 2;
`;

const WelcomeTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const WelcomeSubtitle = styled.p`
  margin: 8px 0 0;
  font-size: 1.1rem;
  opacity: 0.9;
`;

const QuickStatsGrid = styled(Grid)`
  margin-bottom: 24px;
`;

const QuickStatCard = styled(Paper)`
  padding: 20px;
  height: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-4px);
  }
`;

const StatIcon = styled.div<{ $color: string }>`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.$color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  svg {
    font-size: 24px;
    color: white;
  }
`;

const StatValue = styled(Typography)`
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
`;

const StatLabel = styled(Typography)`
  color: #666;
  font-size: 0.9rem !important;
`;

const ShortcutsGrid = styled(Grid)`
  margin-bottom: 24px;
`;

const ShortcutCard = styled(Paper)`
  padding: 20px;
  height: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ShortcutIcon = styled.div<{ $color: string }>`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.$color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  svg {
    font-size: 24px;
    color: white;
  }
`;

const ShortcutTitle = styled(Typography)`
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  margin-bottom: 8px !important;
`;

const ShortcutDescription = styled(Typography)`
  color: #666;
  font-size: 0.9rem !important;
`;

const NotificationBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: #f44336;
  }
`;

interface DashboardStats {
  totalResidents: number;
  pendingApprovals: number;
  todayVends: number;
  walletFunding: number;
}

const AdminDashboard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [admin, setAdmin] = useState<AdminData | null>(null);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<DashboardStats>({
    totalResidents: 0,
    pendingApprovals: 0,
    todayVends: 0,
    walletFunding: 0,
  });
  const location = useLocation();
  const navigate = useNavigate();

  // Add new state for notifications
  const [notifications] = useState(3); // This would come from your API in a real app

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const currentAdmin = getCurrentAdmin();
      if (!currentAdmin) return;

      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split('T')[0];

      // Fetch residents data
      const residentsResponse = isSuperAdmin(currentAdmin)
        ? await getResidents({ limit: 1 })
        : await getManagerResidents(currentAdmin.id, { limit: 1 });

      // Fetch pending users
      const pendingUsersResponse = await fetchPendingUsers(today, today);

      // Fetch today's vends
      const vendsResponse = isSuperAdmin(currentAdmin) 
      ? await fetchVendTransactions({
        page: 1,
        limit: 1,
        startDate: today,
        endDate: today
      })
      : await fetchVendTransactions({
        page: 1,
        limit: 1,
        startDate: today,
        endDate: today,
        merchant_id: currentAdmin.id
      });

      // Fetch today's wallet funding with all transactions
      const walletFundingResponse = await fetchWalletFunding({
        page: 1,
        limit: 1000, // Large limit to get all transactions for today
        startDate: today,
        endDate: today,
        status: 'completed', // Only count completed transactions
      });

      // Calculate total wallet funding for today from completed transactions
      const totalWalletFunding = walletFundingResponse.transactions
        .filter(transaction => transaction.status.toLowerCase() === 'completed')
        .reduce((sum, transaction) => sum + parseFloat(transaction.total_amount), 0);

      setStats({
        totalResidents: residentsResponse.data.pagination.total,
        pendingApprovals: pendingUsersResponse?.length || 0,
        todayVends: vendsResponse.pagination.total,
        walletFunding: totalWalletFunding,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentAdmin = getCurrentAdmin();
    if (!currentAdmin) {
      navigate('/admin/login');
      return;
    }
    setAdmin(currentAdmin);
    fetchDashboardData();
  }, [navigate]);

  const isActive = (path: string) => location.pathname.includes(path);
  const isReportsActive = location.pathname.includes('/admin/reports');

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = () => {
    logout();
    setShowLogoutConfirm(false);
    navigate('/admin/login');
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirm(false);
  };

  if (!admin) return null;

  const isSuperAdminUser = isSuperAdmin(admin);

  const quickStats = [
    {
      label: 'Total Residents',
      value: stats.totalResidents.toLocaleString(),
      icon: <FaUsers />,
      color: '#0ea5e9',
      link: '/admin/residents'
    },
    ...(isSuperAdminUser ? [
      {
        label: 'Pending Approvals',
        value: stats.pendingApprovals.toLocaleString(),
        icon: <FaUserPlus />,
        color: '#f59e0b',
        link: '/admin/newusers'
      },
      {
        label: 'Today\'s Vends',
        value: stats.todayVends.toLocaleString(),
        icon: <FaBolt />,
        color: '#10b981',
        link: '/admin/reports/vend'
      },
      {
        label: 'Today\'s Wallet Funding',
        value: `₦${stats.walletFunding.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
        icon: <FaWallet />,
        color: '#6366f1',
        link: '/admin/reports/wallet-funding'
      }
    ] : [
      {
        label: 'Today\'s Vends',
        value: stats.todayVends.toLocaleString(),
        icon: <FaBolt />,
        color: '#10b981',
        link: '/admin/reports/vend'
      }
    ])
  ];

  const shortcuts = [
    {
      title: 'Manual Vend',
      description: 'Process vend requests manually',
      icon: <FaBolt />,
      color: '#10b981',
      link: '/admin/vend/manual'
    },
    {
      title: 'Service Fee',
      description: 'Manage service fee payments',
      icon: <FaFileInvoiceDollar />,
      color: '#f59e0b',
      link: '/admin/reports/service-fee'
    },
    ...(isSuperAdminUser ? [
      {
        title: 'Water Deposit',
        description: 'Track water deposit payments',
        icon: <FaWater />,
        color: '#0ea5e9',
        link: '/admin/reports/water-deposit'
      },
      {
        title: 'Diesel Deposit',
        description: 'Monitor diesel deposit payments',
        icon: <FaGasPump />,
        color: '#f97316',
        link: '/admin/reports/diesel-deposit'
      }
    ] : [])
  ];

  return (
    <DashboardContainer>
      <MobileOverlay $isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <Sidebar $isOpen={isOpen}>
        <SidebarHeader>
          <LogoContainer to="/">
            <LogoImage src="/logo.png" alt="Ressydent Logo" />
            <Logo $isOpen={isOpen}>Ressydent Admin</Logo>
          </LogoContainer>
          <MenuToggle $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MenuToggle>
        </SidebarHeader>

        <AdminInfo>
          <AdminName $isOpen={isOpen}>{admin.name}</AdminName>
          <AdminRole $isOpen={isOpen}>{admin.adminType}</AdminRole>
        </AdminInfo>

        <NavSection>
          <NavItem to="/admin" $active={location.pathname === '/admin'} $isOpen={isOpen}>
            <FaChartLine />
            <span>Dashboard</span>
          </NavItem>

          <NavItem to="/admin/residents" $active={isActive('residents')} $isOpen={isOpen}>
            <FaUsers />
            <span>Resident Management</span>
          </NavItem>
          
          {isSuperAdminUser && (
            <NavItem to="/admin/newusers" $active={isActive('newusers')} $isOpen={isOpen}>
              <FaUserPlus />
              <span>Pending Approvals</span>
            </NavItem>
          )}

          <NavGroup $isOpen={reportsOpen}>
            <NavGroupHeader 
              onClick={() => setReportsOpen(!reportsOpen)}
              $active={isReportsActive}
              $isOpen={isOpen}
            >
              <NavGroupTitle $isOpen={isOpen}>
                <FaFileAlt />
                <span>Reports</span>
              </NavGroupTitle>
              {isOpen && (reportsOpen ? <FaChevronDown /> : <FaChevronRight />)}
            </NavGroupHeader>
            
            <SubNav $isOpen={reportsOpen && isOpen}>
              {isSuperAdminUser && (
                <SubNavItem to="/admin/reports/wallet-funding" $active={isActive('wallet-funding')} $isOpen={isOpen}>
                  <FaWallet />
                  <span>Wallet Funding</span>
                </SubNavItem>
              )}
              <SubNavItem to="/admin/reports/vend" $active={isActive('vend')} $isOpen={isOpen}>
                <FaReceipt />
                <span>Vend</span>
              </SubNavItem>
              <SubNavItem to="/admin/reports/service-fee" $active={isActive('service-fee')} $isOpen={isOpen}>
                <FaMoneyBill />
                <span>Service Fee</span>
              </SubNavItem>
              <SubNavItem to="/admin/reports/water-deposit" $active={isActive('water-deposit')} $isOpen={isOpen}>
                <FaTint />
                <span>Water Deposit</span>
              </SubNavItem>
              <SubNavItem to="/admin/reports/diesel-deposit" $active={isActive('diesel-deposit')} $isOpen={isOpen}>
                <FaGasPump />
                <span>Diesel Deposit</span>
              </SubNavItem>
              <SubNavItem to="/admin/reports/visitors" $active={isActive('visitors')} $isOpen={isOpen}>
                <FaUserFriends />
                <span>Visitors</span>
              </SubNavItem>
              {isSuperAdminUser && (
                <SubNavItem to="/admin/reports/payout" $active={isActive('reports/payout')} $isOpen={isOpen}>
                  <FaMoneyCheck />
                  <span>Payout Report</span>
                </SubNavItem>
              )}
            </SubNav>
          </NavGroup>

          <NavItem to="/admin/vend/manual" $active={isActive('vend/manual')} $isOpen={isOpen}>
            <FaBolt />
            <span>Manual Vend</span>
          </NavItem>

          <NavItem to="/admin/vend/requests" $active={isActive('vend/requests')} $isOpen={isOpen}>
            <FaList />
            <span>Vend Requests</span>
          </NavItem>

          {isSuperAdminUser && (
            <>
              <NavItem to="/admin/payouts" $active={isActive('payouts')} $isOpen={isOpen}>
                <FaMoneyBillWave />
                <span>Create Payout</span>
              </NavItem>
              <NavItem to="/admin/estates" $active={isActive('estates')} $isOpen={isOpen}>
                <FaBuilding />
                <span>Estate Management</span>
              </NavItem>
              <NavItem to="/admin/reverify-payment" $active={isActive('reverify-payment')} $isOpen={isOpen}>
                <FaSync />
                <span>Reverify Payment</span>
              </NavItem>
            </>
          )}
        </NavSection>

        <LogoutButton $isOpen={isOpen} onClick={handleLogoutClick}>
          <FaSignOutAlt />
          <span>Logout</span>
        </LogoutButton>
      </Sidebar>

      <ContentArea $sidebarOpen={isOpen}>
        {location.pathname === '/admin' ? (
          <>
            <WelcomeSection>
              <WelcomeContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <div>
                    <WelcomeTitle>Welcome to Ressydent Admin</WelcomeTitle>
                    <WelcomeSubtitle>
                      {admin?.name}, {admin?.adminType}
                    </WelcomeSubtitle>
                  </div>
                  <NotificationBadge badgeContent={notifications} color="error">
                    <IconButton color="inherit">
                      <FaBell size={24} />
                    </IconButton>
                  </NotificationBadge>
                </Box>
              </WelcomeContent>
            </WelcomeSection>

            <QuickStatsGrid container spacing={3}>
              {loading ? (
                <Grid item xs={12} display="flex" justifyContent="center" p={4}>
                  <CircularProgress />
                </Grid>
              ) : (
                quickStats.map((stat, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Link to={stat.link} style={{ textDecoration: 'none' }}>
                      <QuickStatCard>
                        <StatIcon $color={stat.color}>
                          {stat.icon}
                        </StatIcon>
                        <StatValue>{stat.value}</StatValue>
                        <StatLabel>{stat.label}</StatLabel>
                      </QuickStatCard>
                    </Link>
                  </Grid>
                ))
              )}
            </QuickStatsGrid>

            <Typography variant="h6" gutterBottom>
              Quick Actions
            </Typography>
            <ShortcutsGrid container spacing={3}>
              {shortcuts.map((shortcut, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Link to={shortcut.link} style={{ textDecoration: 'none' }}>
                    <ShortcutCard>
                      <ShortcutIcon $color={shortcut.color}>
                        {shortcut.icon}
                      </ShortcutIcon>
                      <ShortcutTitle>{shortcut.title}</ShortcutTitle>
                      <ShortcutDescription>{shortcut.description}</ShortcutDescription>
                    </ShortcutCard>
                  </Link>
                </Grid>
              ))}
            </ShortcutsGrid>
          </>
        ) : (
        <Outlet />
        )}
      </ContentArea>

      <Dialog
        open={showLogoutConfirm}
        onClose={handleLogoutCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to log out?
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleLogoutCancel}>Cancel</MuiButton>
          <MuiButton onClick={handleLogoutConfirm} color="error">
            Logout
          </MuiButton>
        </DialogActions>
      </Dialog>
    </DashboardContainer>
  );
};

export default AdminDashboard;
