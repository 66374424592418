import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  FormControlLabel,
  Switch,
  Box,
  Grid,
  Card,
  CardContent,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaBuilding, FaChevronLeft, FaSave } from "react-icons/fa";
import client from "../../services/api";

const Container = styled(Paper)`
  padding: 24px;
  margin: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #1a1a2e;
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
`;

const CardTitle = styled(Typography)`
  font-weight: 600;
  color: #1a1a2e;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddNewEstate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    min_vend: 0,
    max_vend: 0,
    transaction_fee: 0,
    service_charge: 0,
    services: {
      powerVend: false,
      serviceCharge: false,
      waterDeposit: false,
      dieselDeposit: false,
    },
  });

  const navigate = useNavigate();

  const handleChange = (field: string, value: any) => {
    if (field.startsWith('services.')) {
      const serviceField = field.split('.')[1];
      setFormData(prev => ({
        ...prev,
        services: {
          ...prev.services,
          [serviceField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const payload = {
        name: formData.name,
        address: formData.address,
        min_vend: formData.min_vend,
        max_vend: formData.max_vend,
        transaction_fee: formData.transaction_fee,
        service_charge: formData.service_charge,
        service_charge_status: formData.services.serviceCharge,
        services: formData.services,
      };

      await client.post("/api/estates", payload);
      setSuccess(true);
      setTimeout(() => {
        navigate("/admin/estates");
      }, 2000);
    } catch (error: any) {
      console.error("Error saving estate:", error);
      setError(error.response?.data?.message || "Failed to create estate. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container elevation={0}>
      <Header>
        <IconButton onClick={() => navigate("/admin/estates")} size="small">
          <FaChevronLeft />
        </IconButton>
        <Title variant="h4">
          <FaBuilding />
          Add New Estate
        </Title>
      </Header>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Basic Information</CardTitle>
              <TextField
                label="Estate Name"
                value={formData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Estate Address"
                value={formData.address}
                onChange={(e) => handleChange("address", e.target.value)}
                fullWidth
                margin="normal"
                required
              />
            </CardContent>
          </StyledCard>

          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Vending Settings</CardTitle>
              <TextField
                label="Minimum Vend Amount"
                type="number"
                value={formData.min_vend}
                onChange={(e) => handleChange("min_vend", Number(e.target.value))}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                }}
              />
              <TextField
                label="Maximum Vend Amount"
                type="number"
                value={formData.max_vend}
                onChange={(e) => handleChange("max_vend", Number(e.target.value))}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                }}
              />
              <TextField
                label="Transaction Fee"
                type="number"
                value={formData.transaction_fee}
                onChange={(e) => handleChange("transaction_fee", Number(e.target.value))}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                }}
              />
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Service Settings</CardTitle>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.services.powerVend}
                    onChange={(e) => handleChange("services.powerVend", e.target.checked)}
                  />
                }
                label="Power Vend"
              />
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.services.serviceCharge}
                      onChange={(e) => handleChange("services.serviceCharge", e.target.checked)}
                    />
                  }
                  label="Service Charge"
                />
                {formData.services.serviceCharge && (
                  <TextField
                    label="Service Charge Amount"
                    type="number"
                    value={formData.service_charge}
                    onChange={(e) => handleChange("service_charge", Number(e.target.value))}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                    }}
                  />
                )}
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.services.waterDeposit}
                      onChange={(e) => handleChange("services.waterDeposit", e.target.checked)}
                    />
                  }
                  label="Water Deposit"
                />
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.services.dieselDeposit}
                      onChange={(e) => handleChange("services.dieselDeposit", e.target.checked)}
                    />
                  }
                  label="Diesel Deposit"
                />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          onClick={() => navigate("/admin/estates")}
          startIcon={<FaChevronLeft />}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={loading}
          startIcon={<FaSave />}
        >
          Create Estate
        </Button>
      </Box>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert severity="success" onClose={() => setSuccess(false)}>
          Estate created successfully! Redirecting...
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddNewEstate;
