import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  Alert,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Estate } from '../../../types/admin';
import { ServiceType, SERVICE_TYPE_OPTIONS } from '../../../types/payout';
import { createPayout } from '../../../services/PayoutService';

const Container = styled.div`
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled(Typography)`
  margin-bottom: 24px;
`;

const FormContainer = styled(Paper)`
  padding: 24px;
`;

const FileUploadContainer = styled.div`
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
  
  &:hover {
    border-color: #666;
  }
`;

interface CreatePayoutProps {
  estates: Estate[];
}

const CreatePayout: React.FC<CreatePayoutProps> = ({ estates }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  
  const [formData, setFormData] = useState({
    estate_id: '',
    service_type: [] as ServiceType[],
    transactions_period_start: '',
    transactions_period_end: '',
    amount: '',
    proof_file: null as File | null,
  });
  
  const handleServiceTypeChange = (event: any) => {
    const value = event.target.value as ServiceType[];
    setFormData({ ...formData, service_type: value });
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFormData({ ...formData, proof_file: event.target.files[0] });
    }
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      // Get estate manager ID from selected estate
      const selectedEstate = estates.find(e => e.id === Number(formData.estate_id));
      if (!selectedEstate) throw new Error('Invalid estate selected');
      
      // Since we only support single service type for now, use the first selected one
      const serviceType = formData.service_type[0];
      if (!serviceType) throw new Error('Please select a service type');
      
      await createPayout({
        estate_id: Number(formData.estate_id),
        manager_user_id: Number(selectedEstate.manager_user_id),
        service_type: serviceType,
        transactions_period_start: formData.transactions_period_start,
        transactions_period_end: formData.transactions_period_end,
        proof_file: formData.proof_file || '',
        amount: formData.amount
      });
      
      setSuccess(true);
      setTimeout(() => {
        navigate('/admin/reports/payout');
      }, 2000);
    } catch (err: any) {
      setError(err.message || 'Failed to create payout');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container>
      <Title variant="h4">Create Payout</Title>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Payout created successfully! Redirecting...
        </Alert>
      )}
      
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Estate</InputLabel>
                <Select
                  value={formData.estate_id}
                  onChange={(e) => setFormData({ ...formData, estate_id: e.target.value })}
                  required
                >
                  {estates.map((estate) => (
                    <MenuItem key={estate.id} value={estate.id}>
                      {estate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Service Type</InputLabel>
                <Select
                  multiple
                  value={formData.service_type}
                  onChange={handleServiceTypeChange}
                  required
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {(selected as ServiceType[]).map((value) => (
                        <Chip
                          key={value}
                          label={SERVICE_TYPE_OPTIONS.find(opt => opt.value === value)?.label}
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                >
                  {SERVICE_TYPE_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Please select one service type</FormHelperText>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Period Start"
                value={formData.transactions_period_start}
                onChange={(e) => setFormData({ ...formData, transactions_period_start: e.target.value })}
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Period End"
                value={formData.transactions_period_end}
                onChange={(e) => setFormData({ ...formData, transactions_period_end: e.target.value })}
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Amount"
                value={formData.amount}
                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                required
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth error={!formData.proof_file}>
                <InputLabel shrink>Proof of Payment</InputLabel>
                <FileUploadContainer
                  onClick={() => document.getElementById('proof-file-input')?.click()}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : formData.proof_file ? (
                    <Typography>
                      Selected file: {formData.proof_file.name}
                    </Typography>
                  ) : (
                    <Typography color="textSecondary">
                      Click to upload proof of payment
                    </Typography>
                  )}
                </FileUploadContainer>
                <input
                  id="proof-file-input"
                  type="file"
                  accept="image/*,.pdf"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  required
                />
                <FormHelperText>
                  Upload proof of payment (PDF or Image)
                </FormHelperText>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/admin/reports/payout')}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Create Payout
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
    </Container>
  );
};

export default CreatePayout; 