import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  FormControlLabel,
  Switch,
  IconButton,
  Snackbar,
  Alert,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { FaBuilding, FaChevronLeft, FaSave } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { getEstate } from '../../services/EstateService';
import { Estate } from '../../types/Estate';
import client from '../../services/api';

const Container = styled(Paper)`
  padding: 24px;
  margin: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #1a1a2e;
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
`;

const CardTitle = styled(Typography)`
  font-weight: 600;
  color: #1a1a2e;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionDivider = styled(Divider)`
  margin: 24px 0;
`;

const EstateSetup: React.FC = () => {
  const { estateId } = useParams<{ estateId: string }>();
  const [estate, setEstate] = useState<Estate | null>(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEstateDetails();
  }, [estateId]);

  const fetchEstateDetails = async () => {
    setLoading(true);
    try {
      const response = await getEstate(Number(estateId));
      if (response) {
        setEstate(response);
      }
    } catch (error) {
      console.error('Error fetching estate details:', error);
      setErrorMessage('Failed to fetch estate details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!estate) return;

    setLoading(true);
    try {
      const payload = {
        name: estate.name,
        address: estate.address,
        service_charge_status: estate.service_charge_status,
        service_charge: estate.service_charge,
        diesel_fee_status: estate.diesel_fee_status,
        diesel_deposit: estate.diesel_deposit,
        water_fee_status: estate.water_fee_status,
        water_deposit: estate.water_deposit,
        vend_status: estate.vend_status,
        settings: {
          min_vend: estate.settings.min_vend,
          max_vend: estate.settings.max_vend
        }
      };

      await client.put(`/api/estates/${estate.id}`, payload);
      setSuccessMessage('Estate details saved successfully.');
      setTimeout(() => {
        navigate('/admin/estates');
      }, 2000);
    } catch (error: any) {
      console.error('Error saving estate details:', error);
      setErrorMessage(error.response?.data?.message || 'Failed to save estate details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: string, value: any) => {
    if (!estate) return;

    setEstate({
      ...estate,
      [field]: value
    });
  };

  if (loading || !estate) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container elevation={0}>
      <Header>
        <IconButton onClick={() => navigate('/admin/estates')} size="small">
          <FaChevronLeft />
        </IconButton>
        <Title variant="h4">
          <FaBuilding />
          Estate Setup
        </Title>
      </Header>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Basic Information</CardTitle>
              <TextField
                label="Estate Name"
                value={estate.name}
                onChange={(e) => handleChange('name', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Estate Address"
                value={estate.address}
                onChange={(e) => handleChange('address', e.target.value)}
                fullWidth
                margin="normal"
              />
            </CardContent>
          </StyledCard>

          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Vending Settings</CardTitle>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={estate.vend_status}
                      onChange={(e) => handleChange('vend_status', e.target.checked)}
                    />
                  }
                  label="Power Vend"
                />
                {estate.vend_status && (
                  <>
                    <TextField
                      label="Minimum Vend Amount (Single Vend)"
                      type="number"
                      value={estate.settings.min_vend}
                      onChange={(e) => handleChange('settings.min_vend', e.target.value)}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                      }}
                    />
                    <TextField
                      label="Maximum Vend Amount (Monthly Limit)"
                      type="number"
                      value={estate.settings.max_vend}
                      onChange={(e) => handleChange('settings.max_vend', e.target.value)}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                      }}
                    />
                  </>
                )}
              </Box>
            </CardContent>
          </StyledCard>

          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Service Charges</CardTitle>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={estate.service_charge_status}
                      onChange={(e) => handleChange('service_charge_status', e.target.checked)}
                    />
                  }
                  label="Service Charge"
                />
                {estate.service_charge_status && (
                  <TextField
                    label="Service Charge Amount"
                    type="number"
                    value={estate.service_charge}
                    onChange={(e) => handleChange('service_charge', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                    }}
                  />
                )}
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">Service Settings</CardTitle>
              <FormControlLabel
                control={
                  <Switch
                    checked={estate.water_fee_status}
                    onChange={(e) => handleChange('water_fee_status', e.target.checked)}
                  />
                }
                label="Water Deposit"
              />
              {estate.water_fee_status && (
                <TextField
                  label="Water Deposit Amount"
                  type="number"
                  value={estate.water_deposit}
                  onChange={(e) => handleChange('water_deposit', e.target.value)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                  }}
                />
              )}
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={estate.diesel_fee_status}
                      onChange={(e) => handleChange('diesel_fee_status', e.target.checked)}
                    />
                  }
                  label="Diesel Deposit"
                />
                {estate.diesel_fee_status && (
                  <TextField
                    label="Diesel Deposit Amount"
                    type="number"
                    value={estate.diesel_deposit}
                    onChange={(e) => handleChange('diesel_deposit', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1 }}>₦</Typography>,
                    }}
                  />
                )}
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          onClick={() => navigate('/admin/estates')}
          startIcon={<FaChevronLeft />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={loading}
          startIcon={<FaSave />}
        >
          Save Changes
        </Button>
      </Box>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default EstateSetup;

