import { Estate as FullEstate } from './Estate';

export type Estate = Pick<FullEstate, 'id' | 'name' | 'address' | 'manager_user_id'>;

export interface AdminData {
  id: number;
  email: string;
  name: string;
  role: 1 | 2; // 1 for Super Admin, 2 for Admin
  adminType: 'Super Admin' | 'Admin';
  access_token: string;
  estates: Estate[] | 'all';
}

export interface AdminLoginResponse {
  status: boolean;
  message: string;
  data: AdminData;
}

export interface AdminLoginCredentials {
  email: string;
  password: string;
}

// Helper function to check if admin is super admin
export const isSuperAdmin = (admin: AdminData): boolean => {
  return admin.role === 1 || admin.adminType === 'Super Admin';
};

// Helper function to get admin's estates
export const getAdminEstates = (admin: AdminData): Estate[] => {
  if (admin.estates === 'all') {
    return [];
  }
  return admin.estates;
};

// Helper function to check if admin has access to estate
export const hasEstateAccess = (admin: AdminData, estateId: number): boolean => {
  if (admin.estates === 'all') return true;
  return admin.estates.some(estate => estate.id === estateId);
}; 