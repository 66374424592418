import { useState, useEffect } from 'react';
import { isSuperAdmin } from '../types/admin';
import { getCurrentAdmin } from '../services/AdminService';
import { getAllEstates } from '../services/EstateService';
import { Estate } from '../types/Estate';

export const useEstates = () => {
  const [estates, setEstates] = useState<Estate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEstates = async () => {
      try {
        const admin = getCurrentAdmin();
        if (!admin) {
          setError('No admin session found');
          return;
        }

        if (isSuperAdmin(admin)) {
          const allEstates = await getAllEstates();
          if (allEstates) {
            setEstates(allEstates);
          }
        } else if (Array.isArray(admin.estates)) {
          // For non-super admins, fetch full estate details for their estates
          const estatePromises = admin.estates.map(estate => getAllEstates());
          const estateResults = await Promise.all(estatePromises);
          const fullEstates = estateResults.flatMap(result => result || []);
          setEstates(fullEstates);
        }
      } catch (err) {
        setError('Failed to fetch estates');
        console.error('Error fetching estates:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEstates();
  }, []);

  return { estates, loading, error };
}; 