import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../../services/AdminService';
import { Alert, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const LoginBox = styled.div`
  background: white;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 2;
  backdrop-filter: blur(10px);
`;

const LogoContainer = styled(Link)`
  display: block;
  text-align: center;
  margin-bottom: 30px;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Logo = styled.img`
  width: 180px;
  height: auto;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #1a1a2e;
  margin-bottom: 30px;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #f8fafc;

  &:focus {
    outline: none;
    border-color: #0ea5e9;
    background: white;
    box-shadow: 0 0 0 3px rgba(14, 165, 233, 0.1);
  }

  &:disabled {
    background: #f1f5f9;
    cursor: not-allowed;
  }
`;

const Button = styled.button<{ $loading?: boolean }>`
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: ${props => props.$loading ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.$loading ? 0.7 : 1};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;

  &:hover {
    transform: ${props => props.$loading ? 'none' : 'translateY(-2px)'};
    box-shadow: ${props => props.$loading ? 'none' : '0 4px 12px rgba(14, 165, 233, 0.3)'};
  }
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 20px;
  border-radius: 8px;
`;

const RememberMeContainer = styled.div`
  margin-top: -10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await login({ email, password });
      
      if (rememberMe) {
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('savedEmail', email);
      } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('savedEmail');
      }
      
      navigate('/admin');
    } catch (error: any) {
      setError(error.response?.data?.message || 'Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const remembered = localStorage.getItem('rememberMe') === 'true';
    const savedEmail = localStorage.getItem('savedEmail');
    
    if (remembered && savedEmail) {
      setRememberMe(true);
      setEmail(savedEmail);
    }
  }, []);

  return (
    <LoginContainer>
      <LoginBox>
        <LogoContainer to="/">
          <Logo src="/logo.png" alt="Ressydent Logo" />
        </LogoContainer>
        <Title>Admin Login</Title>
        <Form onSubmit={handleSubmit}>
          {error && (
            <ErrorMessage severity="error">
              {error}
            </ErrorMessage>
          )}
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
          <RememberMeContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  disabled={loading}
                  color="primary"
                />
              }
              label="Remember me"
            />
          </RememberMeContainer>
          <Button type="submit" $loading={loading} disabled={loading}>
            {loading ? (
              <>
                <CircularProgress size={20} color="inherit" />
                Logging in...
              </>
            ) : (
              'Login'
            )}
          </Button>
        </Form>
      </LoginBox>
    </LoginContainer>
  );
};

export default AdminLogin;
