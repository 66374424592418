import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createVendRequest } from '../../../services/VendService';
import { getCurrentAdmin } from '../../../services/AdminService';

const Container = styled.div`
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
`;

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ManualVend: React.FC = () => {
  const [meterNumber, setMeterNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const admin = getCurrentAdmin();
      if (!admin) {
        throw new Error('No admin session found');
      }

      await createVendRequest({
        meterNumber,
        amount: parseFloat(amount),
        merchantId: admin.id,
      });

      setSuccess('Vend request created successfully');
      setMeterNumber('');
      setAmount('');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to create vend request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Manual Vend
        </Typography>
        <Form onSubmit={handleSubmit}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          <TextField
            label="Meter Number"
            value={meterNumber}
            onChange={(e) => setMeterNumber(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            fullWidth
            InputProps={{
              startAdornment: <Box component="span" mr={1}>₦</Box>
            }}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            Submit Vend Request
          </LoadingButton>
        </Form>
      </StyledPaper>
    </Container>
  );
};

export default ManualVend; 