import client from './api';
import { PayoutRequest, PayoutResponse } from '../types/payout';
import { uploadToCloudinary } from './CloudinaryService';

interface PayoutFilters {
  page?: number;
  limit?: number;
  estate_id?: number;
  service_type?: string;
  start_date?: string;
  end_date?: string;
}

export const fetchPayouts = async (filters: PayoutFilters = {}): Promise<PayoutResponse> => {
  try {
    const { data } = await client.get<PayoutResponse>('/api/admin/payouts', {
      params: filters,
    });
    return data;
  } catch (error: any) {
    if (error.response?.data) {
      throw new Error(error.response.data.message || 'Failed to fetch payouts');
    }
    throw error;
  }
};

export const createPayout = async (payoutData: PayoutRequest): Promise<void> => {
  let uploadedFileUrl: string | null = null;

  try {
    // If there's a proof file to upload, do it first
    if (payoutData.proof_file instanceof File) {
      uploadedFileUrl = await uploadToCloudinary(payoutData.proof_file);
      // Replace the File object with the uploaded URL
      payoutData = {
        ...payoutData,
        proof_file: uploadedFileUrl
      };
    }

    const { data } = await client.post('/api/admin/payouts', payoutData);
    if (!data.status) {
      throw new Error(data.message);
    }
  } catch (error: any) {
    // If we've uploaded a file but the payout creation failed,
    // include the uploaded file URL in the error for potential cleanup
    if (uploadedFileUrl) {
      const enhancedError = new Error(error.response?.data?.message || 'Failed to create payout');
      (enhancedError as any).uploadedFileUrl = uploadedFileUrl;
      throw enhancedError;
    }

    if (error.response?.data) {
      throw new Error(error.response.data.message || 'Failed to create payout');
    }
    throw error;
  }
}; 