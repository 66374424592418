import client from './api';
import { WalletFundingResponse } from '../types/wallet-funding';

interface FetchWalletFundingParams {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  status?: string;
  meterNumber?: string;
  includeSummary?: boolean;
}

export const fetchWalletFunding = async ({
  page = 1,
  limit = 10,
  startDate,
  endDate,
  status,
  meterNumber,
  includeSummary = false,
}: FetchWalletFundingParams): Promise<WalletFundingResponse> => {
  try {
    const response = await client.get('/api/admin/reports/wallet-funding', {
      params: {
        page,
        limit,
        startDate,
        endDate,
        status,
        meterNumber,
        includeSummary,
      },
    });
    // The API returns the data wrapped in a data property
    return response.data.data;
  } catch (error) {
    console.error('Error fetching wallet funding:', error);
    throw error;
  }
}; 