import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getVendRequests, processVendRequest } from '../../../services/VendService';
import { VendRequest } from '../../../types/vend';
import { getCurrentAdmin } from '../../../services/AdminService';
import { isSuperAdmin } from '../../../types/admin';
import { FaEye } from 'react-icons/fa';

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledPaper = styled(Paper)`
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StatusChip = styled(Chip)<{ $status: string }>`
  background-color: ${props => {
    switch (props.$status.toLowerCase()) {
      case 'approved':
        return '#4caf50';
      case 'pending':
        return '#ff9800';
      case 'rejected':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }} !important;
  color: white !important;
`;

const DetailSection = styled.div`
  margin-bottom: 24px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailSectionTitle = styled.h3`
  color: #1a1a2e;
  font-size: 1.1rem;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #eee;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: #666;
`;

const DetailValue = styled.span`
  color: #333;
  text-align: right;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const VendRequests: React.FC = () => {
  const [requests, setRequests] = useState<VendRequest[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<VendRequest | null>(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);

  const admin = getCurrentAdmin();
  const isSuper = admin ? isSuperAdmin(admin) : false;

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getVendRequests({
        page: page + 1,
        limit: rowsPerPage,
      });
      setRequests(response.data.requests);
      setTotal(response.data.pagination.total);
      setTotalPages(response.data.pagination.totalPages);
    } catch (err) {
      console.error('Error fetching vend requests:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProcess = async (action: 'approve' | 'reject') => {
    if (!selectedRequest) return;

    try {
      setProcessing(true);
      setError('');

      if (action === 'reject' && !rejectionReason) {
        setError('Rejection reason is required');
        return;
      }

      await processVendRequest(selectedRequest.id, {
        action,
        rejectionReason: action === 'reject' ? rejectionReason : undefined,
      });

      setSelectedRequest(null);
      setRejectionReason('');
      fetchData();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to process request');
    } finally {
      setProcessing(false);
    }
  };

  const formatAmount = (amount: number) => {
    return `₦${amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Container>
      <Header>
        <Typography variant="h5">Vend Requests</Typography>
      </Header>

      <StyledPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Meter Number</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Requested By</TableCell>
                <TableCell>Estate</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((request) => (
                <TableRow key={request.id} hover>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>{request.meterNumber}</TableCell>
                  <TableCell align="right">{formatAmount(request.amount)}</TableCell>
                  <TableCell>
                    <Typography variant="body2">{request.requester.name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {request.requester.email}
                    </Typography>
                  </TableCell>
                  <TableCell>{request.meterDetails.estateName}</TableCell>
                  <TableCell>
                    <StatusChip
                      label={request.status}
                      $status={request.status}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ActionButtonsContainer>
                      <IconButton
                        size="small"
                        onClick={() => setSelectedRequest(request)}
                        title="View Details"
                      >
                        <FaEye />
                      </IconButton>
                      {isSuper && request.status === 'pending' && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => setSelectedRequest(request)}
                        >
                          Process
                        </Button>
                      )}
                    </ActionButtonsContainer>
                  </TableCell>
                </TableRow>
              ))}
              {loading && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              )}
              {!loading && requests.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No requests found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 50]}
      />

      <Dialog
        open={!!selectedRequest}
        onClose={() => setSelectedRequest(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {isSuper && selectedRequest?.status === 'pending' ? 'Process Vend Request' : 'Vend Request Details'}
        </DialogTitle>
        <DialogContent dividers>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {selectedRequest && (
            <>
              <DetailSection>
                <DetailSectionTitle>Request Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Request ID:</DetailLabel>
                  <DetailValue>{selectedRequest.id}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Status:</DetailLabel>
                  <DetailValue>
                    <StatusChip
                      label={selectedRequest.status}
                      $status={selectedRequest.status}
                      size="small"
                    />
                  </DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Amount:</DetailLabel>
                  <DetailValue>{formatAmount(selectedRequest.amount)}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Date:</DetailLabel>
                  <DetailValue>{formatDate(selectedRequest.created_at)}</DetailValue>
                </DetailRow>
                {selectedRequest.processedAt && (
                  <DetailRow>
                    <DetailLabel>Processed At:</DetailLabel>
                    <DetailValue>{formatDate(selectedRequest.processedAt)}</DetailValue>
                  </DetailRow>
                )}
                {selectedRequest.rejectionReason && (
                  <DetailRow>
                    <DetailLabel>Rejection Reason:</DetailLabel>
                    <DetailValue>{selectedRequest.rejectionReason}</DetailValue>
                  </DetailRow>
                )}
              </DetailSection>

              <DetailSection>
                <DetailSectionTitle>Meter Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Meter Number:</DetailLabel>
                  <DetailValue>{selectedRequest.meterNumber}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>House Number:</DetailLabel>
                  <DetailValue>{selectedRequest.meterDetails.houseNumber}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Estate:</DetailLabel>
                  <DetailValue>{selectedRequest.meterDetails.estateName}</DetailValue>
                </DetailRow>
              </DetailSection>

              <DetailSection>
                <DetailSectionTitle>User Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Name:</DetailLabel>
                  <DetailValue>{selectedRequest.meterDetails.userName}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Email:</DetailLabel>
                  <DetailValue>{selectedRequest.meterDetails.userEmail}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Phone:</DetailLabel>
                  <DetailValue>{selectedRequest.meterDetails.phoneNumber}</DetailValue>
                </DetailRow>
              </DetailSection>

              <DetailSection>
                <DetailSectionTitle>Requester Information</DetailSectionTitle>
                <DetailRow>
                  <DetailLabel>Name:</DetailLabel>
                  <DetailValue>{selectedRequest.requester.name}</DetailValue>
                </DetailRow>
                <DetailRow>
                  <DetailLabel>Email:</DetailLabel>
                  <DetailValue>{selectedRequest.requester.email}</DetailValue>
                </DetailRow>
              </DetailSection>

              {selectedRequest.status === 'processed' && selectedRequest.vendingDetails && (
                <DetailSection>
                  <DetailSectionTitle>Vending Details</DetailSectionTitle>
                  <DetailRow>
                    <DetailLabel>Amount:</DetailLabel>
                    <DetailValue>{formatAmount(selectedRequest.vendingDetails.amount)}</DetailValue>
                  </DetailRow>
                  <DetailRow>
                    <DetailLabel>Vend Value:</DetailLabel>
                    <DetailValue>{formatAmount(selectedRequest.vendingDetails.vendValue)}</DetailValue>
                  </DetailRow>
                  <DetailRow>
                    <DetailLabel>Units:</DetailLabel>
                    <DetailValue>{selectedRequest.vendingDetails.units}</DetailValue>
                  </DetailRow>
                  <DetailRow>
                    <DetailLabel>Token:</DetailLabel>
                    <DetailValue>{selectedRequest.vendingDetails.token}</DetailValue>
                  </DetailRow>
                  <DetailRow>
                    <DetailLabel>Tariff:</DetailLabel>
                    <DetailValue>{selectedRequest.vendingDetails.tariff}</DetailValue>
                  </DetailRow>
                </DetailSection>
              )}

              {isSuper && selectedRequest.status === 'pending' && (
                <TextField
                  label="Rejection Reason"
                  multiline
                  rows={3}
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                  required={true}
                  error={!rejectionReason && error === 'Rejection reason is required'}
                  helperText={!rejectionReason && error === 'Rejection reason is required' ? 'Please provide a reason for rejection' : ''}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedRequest(null)}>
            {isSuper && selectedRequest?.status === 'pending' ? 'Cancel' : 'Close'}
          </Button>
          {isSuper && selectedRequest?.status === 'pending' && (
            <>
              <LoadingButton
                onClick={() => handleProcess('reject')}
                color="error"
                loading={processing}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                onClick={() => handleProcess('approve')}
                color="primary"
                loading={processing}
              >
                Approve
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default VendRequests; 