import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';
import { FaBolt, FaMoneyBill, FaTint, FaUserFriends, FaGooglePlay, FaApple } from 'react-icons/fa';

const HomeContainer = styled.div`
  font-family: 'Inter', sans-serif;
`;

const HeroSection = styled.div`
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: white;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${process.env.PUBLIC_URL + '/pattern.png'}) repeat;
    opacity: 0.1;
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  margin-bottom: 40px;
`;

const Logo = styled.img`
  width: 220px;
  height: auto;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
`;

const Header = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubHeader = styled.p`
  font-size: 1.4rem;
  line-height: 1.8;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.9);
`;

const AppStoreSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
`;

const StoreButton = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 32px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 1.8rem;
  }
`;

const FeaturesSection = styled.section`
  padding: 80px 20px;
  background: #f8fafc;
`;

const FeaturesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FeaturesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1a1a2e;
  font-weight: 700;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

const FeatureCard = styled.div`
  background: white;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
`;

const FeatureIcon = styled.div`
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #0ea5e9 0%, #38bdf8 100%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: white;
  font-size: 24px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #1a1a2e;
  font-weight: 600;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  color: #475569;
  line-height: 1.6;
`;

const CallToAction = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: white;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  padding: 18px 40px;
  font-size: 1.3rem;
  color: white;
  background: linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%);
  border-radius: 30px;
  text-decoration: none;
  box-shadow: 0 4px 15px rgba(14, 165, 233, 0.3);
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(14, 165, 233, 0.4);
  }
`;

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Ressydent - Simplifying Estate Management</title>
        <meta name="description" content="Manage your estate with ease using Ressydent. Pay bills, manage utilities, and handle visitor access seamlessly." />
        <meta name="keywords" content="estate management, resident services, Ressydent platform, utility payments" />
      </Helmet>
      <HomeContainer>
        <TopNavBar />
        
        <HeroSection>
          <HeroContent>
            <LogoContainer>
              <Logo src={process.env.PUBLIC_URL + '/logo.png'} alt="Ressydent Logo" />
            </LogoContainer>
            <Header>Welcome to Ressydent</Header>
            <SubHeader>
              Experience seamless estate living with Ressydent. Manage your utilities, payments, and visitor access
              all from one powerful platform designed to enhance your residential experience.
            </SubHeader>
            
            <AppStoreSection>
              <StoreButton href="https://play.google.com/store/apps/details?id=com.Ressydent&hl=en" target="_blank" rel="noopener noreferrer">
                <FaGooglePlay /> Get it on Google Play
              </StoreButton>
              <StoreButton href="https://apps.apple.com/ng/app/ressydent/id6741027949" target="_blank" rel="noopener noreferrer">
                <FaApple /> Download on App Store
              </StoreButton>
            </AppStoreSection>
          </HeroContent>
        </HeroSection>

        <FeaturesSection>
          <FeaturesContent>
            <FeaturesTitle>Smart Features for Modern Living</FeaturesTitle>
            <FeaturesGrid>
              <FeatureCard>
                <FeatureIcon>
                  <FaBolt />
                </FeatureIcon>
                <FeatureTitle>Electricity Vending</FeatureTitle>
                <FeatureDescription>
                  Purchase electricity tokens instantly, monitor your consumption, and manage your power usage efficiently.
                </FeatureDescription>
              </FeatureCard>
              
              <FeatureCard>
                <FeatureIcon>
                  <FaMoneyBill />
                </FeatureIcon>
                <FeatureTitle>Service Charge Payments</FeatureTitle>
                <FeatureDescription>
                  Handle estate service fees with ease, maintain payment records, and stay up-to-date with your obligations.
                </FeatureDescription>
              </FeatureCard>
              
              <FeatureCard>
                <FeatureIcon>
                  <FaTint />
                </FeatureIcon>
                <FeatureTitle>Water & Diesel Deposits</FeatureTitle>
                <FeatureDescription>
                  Track and manage your utility deposits seamlessly, ensuring uninterrupted access to essential services.
                </FeatureDescription>
              </FeatureCard>
              
              <FeatureCard>
                <FeatureIcon>
                  <FaUserFriends />
                </FeatureIcon>
                <FeatureTitle>Visitor Management</FeatureTitle>
                <FeatureDescription>
                  Generate secure access codes for visitors, maintain detailed logs, and enhance your estate's security.
                </FeatureDescription>
              </FeatureCard>
            </FeaturesGrid>
          </FeaturesContent>
        </FeaturesSection>

        <CallToAction>
          <CTAButton to="/about">Discover More About Ressydent</CTAButton>
        </CallToAction>
        
        <BottomBar />
      </HomeContainer>
    </>
  );
};

export default Home;